import { inject, Injectable, ViewContainerRef } from "@angular/core";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { DialogWidgetConfigurator } from "../../types";
import { CrosstabWidgetConfig } from "./crosstab-widget.types";
import { CrosstabConfigurationDialog } from "./components/crosstab-configuration-dialog/crosstab-configuration-dialog.component";
import { validateCrosstabConfiguration } from "./components/crosstab-configuration-dialog/crosstab-configuration-validation";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";
import { PivotTableConfigurationDialog } from "../pivot-table";

@Injectable()
export class CrosstabWidgetConfigurator implements DialogWidgetConfigurator<CrosstabWidgetConfig> {
    private _layoutDataSourceService = inject(FlexibleLayoutDataSourcesService);
    private _flexDataClient = inject(FlexDataClientService);
    private _pageReferences = inject(PageReferencesService);
    private _dialog = inject(CrosstabConfigurationDialog).bindViewContainerRef();
    readonly type = "dialog";

    async show(config: CrosstabWidgetConfig): Promise<CrosstabWidgetConfig> {
        const response = await this._dialog.show({
            title: config.title,
            description: config.description,
            tooltip: config.tooltip,
            tooltipLink: config.tooltipLink,
            horizontalDimension: config.horizontalDimension,
            verticalDimension: config.verticalDimension,
            value: config.value,
            referenceIdx: config.referenceIdx,
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            sidebarWidth: config.sidebarWidth,
            cellWidth: config.cellWidth,
            selectedReferences: config.selectedReferences,
            dataSource: config.dataSource
        });

        return {
            ...response
        };
    }

    validate(config: CrosstabWidgetConfig): boolean {
        let scheme = this._flexDataClient.scheme;
        let slots = this._pageReferences.slots;

        if (
            !!config.dataSource &&
            config.dataSource !== this._layoutDataSourceService.defaultLayoutDataSourceCode()
        ) {
            scheme = this._layoutDataSourceService.getDataSourceScheme(config.dataSource);
            slots = config.selectedReferences;
        }

        return validateCrosstabConfiguration(config, scheme, slots);
    }
}
