<div
    class="lgflex-pivot-table-columns-groups__wrapper"
    [class.lgflex-pivot-table-columns-groups__wrapper--has-border]="hideBorder === false"
    #wrapper
>
    <div
        lgScrollable
        cdkDropList
        #scroller
        style="height: 100%; overflow-x: hidden"
        [lgScrollableWrapper]="false"
    >
        <ng-container *ngFor="let group of _columnsAndGroups().value; let idx = index">
            <!-- Render dimension entry -->
            <div
                class="lgflex-pivot-table-columns-groups__row table__row"
                [class.lgflex-pivot-table-columns-groups__row--current]="
                    group.id === _currentSelection
                "
                cdkDrag
                [cdkDragData]="group"
                (cdkDragDropped)="_onDragDrop($event)"
                cdkDragHandle
            >
                <div
                    class="lgflex-pivot-table-columns-groups__row-label"
                    (click)="_onGroupClick(group)"
                >
                    <div [style.width.px]="24" [style.flex-shrink]="0">
                        <lg-icon
                            icon="icon-draggable"
                            class="lgflex-pivot-table-columns-groups__row__hover-only"
                        ></lg-icon>
                    </div>
                    <div
                        *ngIf="!group.columns && !group.isValid"
                        class="lgflex-pivot-table-columns-groups-icon"
                    >
                        <lg-icon
                            icon="icon-recalculation-err"
                            class="lgflex-pivot-table-columns-groups-icon__red"
                        />
                    </div>

                    <div
                        class="lgflex-pivot-table-columns-groups__content crop-text"
                        [title]="group.title"
                        [class.lgflex-pivot-table-columns-groups__bold]="group.columns"
                    >
                        {{ group.title | dimensionName: group.schemaName | modReferenceVariable }}
                    </div>
                </div>
                <div
                    class="lgflex-pivot-table-columns-groups__icons lgflex-pivot-table-columns-groups__row__hover-only"
                >
                    <lg-icon
                        class="lg-icon-menu hoverable"
                        icon="icon-add"
                        [lgTooltip]="
                            (group.columns ? '.AddRootValue' : '.AddNextValue') | lgTranslate
                        "
                        (click)="_showValuesGroupLevelDropdown(group.id, idx)"
                    />
                    <lg-dropdown
                        [current]="null"
                        [condensed]="true"
                        [emptyAcceptable]="true"
                        [definition]="_valuesDropdownDefinition()"
                        [style.visibility]="
                            _isValuesGroupLevelDropdownActive && _currentSelection === group.id
                                ? 'visible'
                                : 'hidden'
                        "
                        (currentChange)="_onValueAsGroupSelect($event)"
                        (activeChange)="_valuesGroupsLevelDropdownActiveChange($event)"
                        class="flex-flexible lgflex-pivot-table-columns-groups__icons-dropdown"
                        className="lg-dropdown lg-dropdown--condensed"
                        style="position: absolute"
                        #valuesGroupLevelDropdown
                    ></lg-dropdown>

                    <lg-icon
                        class="lg-icon-menu hoverable"
                        icon="icon-rule-add"
                        [lgTooltip]="(group.columns ? '.CopyGroup' : '.CopyField') | lgTranslate"
                        (click)="_copyGroup(group.id)"
                    />
                    <lg-icon
                        class="lg-icon-menu hoverable"
                        icon="icon-delete"
                        [lgTooltip]="'.Delete' | lgTranslate"
                        (click)="_removeGroup(group.id, idx)"
                    ></lg-icon>
                </div>
            </div>

            <!-- Render nested levels within the dimension -->
            <ng-container *ngFor="let column of group.columns; let idx = index">
                <div
                    class="lgflex-pivot-table-columns-groups__row table__row"
                    [class.lgflex-pivot-table-columns-groups__row--current]="
                        column.id === _currentSelection
                    "
                    cdkDrag
                    [cdkDragData]="column"
                    (cdkDragDropped)="_onDragDrop($event)"
                    cdkDragHandle
                >
                    <div
                        class="lgflex-pivot-table-columns-groups__row-label"
                        (click)="_onValueClick($event, column, group)"
                    >
                        <div class="lgflex-pivot-table-columns-groups__spacer"></div>
                        <div
                            class="lgflex-pivot-table-columns-groups__row lgflex-pivot-table-columns-groups__row__hover-only lgflex-pivot-table-columns-groups-icon"
                        >
                            <lg-icon icon="icon-draggable"></lg-icon>
                        </div>
                        <div
                            *ngIf="!column.isValid"
                            class="lgflex-pivot-table-columns-groups-icon lgflex-pivot-table-columns-groups-icon-validation"
                        >
                            <lg-icon
                                icon="icon-recalculation-err"
                                class="lgflex-pivot-table-columns-groups-icon__red"
                            />
                        </div>
                        <div
                            class="lgflex-pivot-table-columns-groups__content crop-text"
                            [title]="column.title"
                        >
                            {{ column.title | dimensionName: column.schemaName }}
                        </div>
                    </div>
                    <div
                        class="lgflex-pivot-table-columns-groups__icons lgflex-pivot-table-columns-groups__row__hover-only"
                    >
                        <lg-icon
                            class="lg-icon-menu hoverable"
                            icon="icon-add"
                            [lgTooltip]="'.AddGroupValue' | lgTranslate"
                            (click)="_showValuesGroupDropdown(column.id)"
                        >
                        </lg-icon>
                        <lg-dropdown
                            [current]="null"
                            [condensed]="true"
                            [emptyAcceptable]="true"
                            [definition]="_valuesDropdownDefinition()"
                            [style.visibility]="
                                _isValuesGroupDropdownActive && _currentSelection === column.id
                                    ? 'visible'
                                    : 'hidden'
                            "
                            (activeChange)="_valuesGroupsDropdownActiveChange($event)"
                            (currentChange)="_onValueInGroupSelect($event, group.id, column.id)"
                            class="flex-flexible lgflex-pivot-table-columns-groups__icons-dropdown"
                            className="lg-dropdown lg-dropdown--condensed"
                            style="position: absolute"
                            #valuesGroupDropdown
                        ></lg-dropdown>
                        <lg-icon
                            class="lg-icon-menu hoverable"
                            icon="icon-rule-add"
                            [lgTooltip]="'.CopyField' | lgTranslate"
                            (click)="_copyValue(column.id)"
                        ></lg-icon>
                        <lg-icon
                            class="lg-icon-menu hoverable"
                            icon="icon-delete"
                            [lgTooltip]="'.Delete' | lgTranslate"
                            (click)="_removeValueFromGroup(group, idx)"
                        ></lg-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-container *lgPlaceholderContent="buttonPlaceholderName">
    <lg-dropdown
        [current]="null"
        [condensed]="true"
        [emptyAcceptable]="true"
        [definition]="_valuesDropdownDefinition()"
        [style.visibility]="_isValuesDropdownActive ? 'visible' : 'hidden'"
        (currentChange)="_onValueSelect($event)"
        (activeChange)="_valuesDropdownActiveChange($event)"
        class="add-value flex-flexible"
        className="lg-dropdown lg-dropdown--condensed"
        style="position: absolute"
        #valuesDropdown
    ></lg-dropdown>
    <lg-button
        [buttonClass]="'button button--condensed'"
        textLc=".AddTable"
        icon="icon-add"
        (click)="_addGroup()"
    ></lg-button>
    <lg-button
        style="margin-left: 5px"
        [buttonClass]="'button button--condensed'"
        textLc=".AddDimension"
        icon="icon-add"
        (click)="_showValuesDropdown()"
    ></lg-button>
</ng-container>
