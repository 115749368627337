<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            [(widgetName)]="_widgetName"
            [(widgetDescription)]="_widgetDescription"
            [(widgetTooltip)]="_widgetTooltip"
            [(widgetTooltipLink)]="_widgetTooltipLink"
            [dataSourceCode]="_dataSourceCode()"
            [availableReferences]="_references()"
            [selectedReferences]="_selectedReferences()"
            [isReferenceSlotsValid]="_isReferenceSlotsValid()"
            (onDataSourceChange)="_onDataSourceChange($event)"
            (onSelectedReferencesChange)="_onSelectedReferencesChange($event)"
            (onReferenceSlotsValid)="_isReferenceSlotsValid.set($event)"
        />
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-form-section>
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'.ChartType' | lgTranslate">
                    <lg-dropdown [definition]="_chartTypeDropdown" [(current)]="_chartType" />
                </lgflex-form-row>

                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.Levels' | lgTranslate">
                    <lgflex-pivot-levels-selector-legacy
                        [minRowNum]="3"
                        [levels]="_availableLevels()"
                        [selectedLevelIds]="_selectedLevelsIds()"
                        (onSelectedLevelIdsChange)="_onSelectedLevelIdsChange($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row *ngIf="_sizeDimension != null" [label]="'.Size' | lgTranslate">
                    <div class="size-rows">
                        <div class="size-rows__item">
                            <span>{{ ".Field" | lgTranslate }}</span>
                            <lg-dropdown
                                [definition]="_valueFieldDropdown()!"
                                [(current)]="_sizeDimension.field"
                            />
                        </div>
                        <div class="size-rows__item" *ngIf="_areReferencesAllowed">
                            <span> {{ ".Reference" | lgTranslate }}</span>
                            <lg-dropdown
                                [definition]="_referenceDropdown()"
                                [(current)]="_sizeDimension.reference"
                            />
                        </div>
                    </div>
                </lgflex-form-row>
            </lgflex-form-section-column>
        </lgflex-form-section>
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ ".XAxis" | lgTranslate }}
                </lgflex-form-section-header>
                <lgflex-configuration-dialog-formula
                    *ngIf="_xAxisColumnFormula"
                    [column]="_xAxisColumnFormula"
                    [fieldsDropdownDefinition]="_valueFieldDropdown()"
                    [areReferencesAllowed]="_areReferencesAllowed"
                    [referencesDropdownDefinition]="_referenceDropdown()"
                />
            </lgflex-form-section-column>
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ ".YAxis" | lgTranslate }}
                </lgflex-form-section-header>

                <lgflex-configuration-dialog-formula
                    *ngIf="_yAxisColumnFormula"
                    [column]="_yAxisColumnFormula"
                    [fieldsDropdownDefinition]="_valueFieldDropdown()"
                    [areReferencesAllowed]="_areReferencesAllowed"
                    [referencesDropdownDefinition]="_referenceDropdown()"
                />
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
</lgflex-dialog>
