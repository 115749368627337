<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="false"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            [(widgetName)]="_widgetName"
            [(widgetDescription)]="_widgetDescription"
            [(widgetTooltip)]="_widgetTooltip"
            [(widgetTooltipLink)]="_widgetTooltipLink"
            [dataSourceCode]="_dataSourceCode()"
            [availableReferences]="_references()"
            [selectedReferences]="_selectedReferences()"
            [isReferenceSlotsValid]="_isReferenceSlotsValid()"
            (onDataSourceChange)="_onDataSourceChange($event)"
            (onSelectedReferencesChange)="_onSelectedReferences($event)"
            (onReferenceSlotsValid)="_isReferenceSlotsValid.set($event)"
        />
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'.HorizontalDimension' | lgTranslate">
                    <lg-dropdown
                        [definition]="_horizontalDimensionDropdown()"
                        [current]="_horizontalDimension()"
                        (currentChange)="_createHorizontalDimensionDropdown($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.VerticalDimension' | lgTranslate">
                    <lg-dropdown
                        [definition]="_verticalDimensionDropdown()"
                        [current]="_verticalDimension()"
                        (currentChange)="_createVerticalDimensionDropdown($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.ValueField' | lgTranslate">
                    <lg-dropdown
                        [definition]="_valueFieldDropdown()"
                        [current]="_valueField()"
                        (currentChange)="_onValueFieldChange($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.Reference' | lgTranslate">
                    <lg-dropdown
                        *ngIf="_enableReferenceDropdown()"
                        [definition]="_referenceSlotDropdown()"
                        [current]="_referenceSlot()"
                        (currentChange)="_onReferenceSlotChange($event)"
                    >
                    </lg-dropdown>
                    <input
                        *ngIf="!_enableReferenceDropdown()"
                        type="text"
                        class="input input--wide"
                        [readOnly]="true"
                        [disabled]="true"
                        value="-"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.LeftAxisWidth' | lgTranslate">
                    <input
                        type="number"
                        min="0"
                        [max]="CROSSTAB_CELL_MAX_WIDTH"
                        class="input input--wide"
                        [(ngModel)]="_sidebarWidth"
                        [ngModelOptions]="{ standalone: true }"
                        [required]="true"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.CellsWidth' | lgTranslate">
                    <input
                        type="number"
                        min="0"
                        [max]="CROSSTAB_CELL_MAX_WIDTH"
                        class="input input--wide"
                        [(ngModel)]="_cellWidth"
                        [ngModelOptions]="{ standalone: true }"
                        [required]="true"
                    />
                </lgflex-form-row>
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
</lgflex-dialog>
