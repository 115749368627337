import { computed, inject, Injectable, signal } from "@angular/core";
import { FlexibleLayoutDataSourcesService } from "../../../../services/flexible-layout-data-sources";
import { FieldInfo, ReferenceInfo, ReferenceSlot } from "../../../../types";

@Injectable()
export class PivotTableConfigurationService {
    protected _layoutDataSources: FlexibleLayoutDataSourcesService;

    protected _dataSourceCodeSignal = signal<string>(null);
    protected _schemeSignal = signal<FieldInfo[]>([]);
    protected _referencesSignal = signal<ReferenceInfo[]>([]);
    protected _selectedReferencesSignal = signal<ReferenceSlot[]>([]);

    public dataSourceCode = this._dataSourceCodeSignal.asReadonly();
    public scheme = this._schemeSignal.asReadonly();
    public references = this._referencesSignal.asReadonly();
    public selectedReferences = this._selectedReferencesSignal.asReadonly();
    public isDefaultDataSource = computed(
        () =>
            this.dataSourceCode() === null ||
            this.dataSourceCode() === this._layoutDataSources.defaultLayoutDataSourceCode()
    );

    async setDataSource(dataSourceCode?: string): Promise<void> {
        this._dataSourceCodeSignal.set(dataSourceCode ?? null);
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSources.defaultLayoutDataSourceCode();
        }

        const { scheme, references } = await this._layoutDataSources.getDataSource(dataSourceCode);

        this._schemeSignal.set(scheme);
        this._referencesSignal.set(references);
    }

    setDataSourcesService(layoutDataSourceService: FlexibleLayoutDataSourcesService): void {
        this._layoutDataSources = layoutDataSourceService;
    }

    setSelectedReferences(references: ReferenceSlot[]): void {
        this._selectedReferencesSignal.set(references);
    }
}
