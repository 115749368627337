import { inject, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { IScenario, LG_APP_SESSION, LG_MESSAGE_BUS_SERVICE } from "@logex/framework/lg-application";
import { LgPromptDialog, LgSnackbarService } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { HandleErrorsMixin } from "@logex/mixins";

import { ExportStatus, ExportStatusInfo } from "./exports-notifications.types";
import { ExportsNotificationsGateway } from "../gateways/exports-notifications-gateway.service";
import { mixins } from "@logex/mixin-flavors";
import { firstValueFrom } from "rxjs";
import { DownloadExportService } from "./download-export.service";
import { IAppSession } from "../../../common";

// ------------------------------------------------------------------------------------
export interface ExportsNotificationsService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class ExportsNotificationsService {
    _promptDialog = inject(LgPromptDialog);
    _lgTranslate = inject(LgTranslateService);
    _session = inject<IAppSession<IScenario>>(LG_APP_SESSION);
    private _downloadExport = inject(DownloadExportService);
    private _gateway = inject(ExportsNotificationsGateway);
    private _messageBus = inject(LG_MESSAGE_BUS_SERVICE);
    private _snackbar = inject(LgSnackbarService);

    constructor() {
        this._messageBus.on("OnExportStatusChange", message => this._showNotification(message));
    }

    private _exportTypes = new Map<string, string>();

    // ------------------------------------------------------------------------------------
    private async _showNotification(message: ExportStatusInfo): Promise<void> {
        if (message.status === ExportStatus.InProgress) {
            const exportType = await firstValueFrom(
                this._gateway.getExportType({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    exportId: message.exportId
                })
            );

            if (exportType != null) {
                this._exportTypes.set(
                    message.exportId,
                    exportType.nameLc
                        ? this._lgTranslate.translate(exportType.nameLc)
                        : exportType.name
                );
            }
        }

        const title = this._lgTranslate.translate(
            `_Exports.Status.${ExportStatus[message.status]}`
        );
        const text = this._lgTranslate.translate(
            `_Exports.Status.${ExportStatus[message.status]}Text`,
            { type: this._exportTypes.get(message.exportId) }
        );

        const buttons = message.status === ExportStatus.Success && [
            {
                textLc: "_Exports.Download",
                callback: async () => {
                    await this._downloadExport.do(message.exportId);
                    return true;
                }
            }
        ];

        this._snackbar.show({ title, text, autoHide: true, delayHide: 10000, buttons });

        if (
            message.status === ExportStatus.Success ||
            message.status === ExportStatus.Cancelled ||
            message.status === ExportStatus.Error
        ) {
            this._exportTypes.delete(message.exportId);
        }

        if (message.status === ExportStatus.Error) {
            this._onServerFailure(new HttpErrorResponse({ error: { Message: message.error } }));
            throw message.error;
        }
    }
}
