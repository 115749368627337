<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            [(widgetName)]="_widgetName"
            [(widgetDescription)]="_widgetDescription"
            [(widgetTooltip)]="_widgetTooltip"
            [(widgetTooltipLink)]="_widgetTooltipLink"
            [dataSourceCode]="_dataSourceCode()"
            [availableReferences]="_references()"
            [selectedReferences]="_selectedReferences()"
            [isReferenceSlotsValid]="_isReferenceSlotsValid()"
            (onDataSourceChange)="_onDataSourceChange($event)"
            (onSelectedReferencesChange)="_onSelectedReferencesChange($event)"
            (onReferenceSlotsValid)="_isReferenceSlotsValid.set($event)"
        />
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.Levels' | lgTranslate">
                    <lgflex-pivot-levels-selector-legacy
                        [minRowNum]="3"
                        [levels]="_availableLevels()"
                        [selectedLevelIds]="_selectedLevelsIds()"
                        (onSelectedLevelIdsChange)="_onSelectedLevelIdsChange($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row>
                    <label>
                        <input
                            type="checkbox"
                            lgStyledCheckbox
                            [(ngModel)]="_ignoreOwnFilters"
                            [required]="true"
                        />
                        &nbsp;
                        {{ "_Flexible.WidgetConfiguration.IgnoreOwnFilters" | lgTranslate }}
                    </label>
                </lgflex-form-row>

                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.ValueField' | lgTranslate">
                    <lg-dropdown [definition]="_valueFieldDropdown()!" [(current)]="_valueField!" />
                </lgflex-form-row>

                <lgflex-form-row
                    *ngIf="_referenceDropdown != null && !_allowMultipleReferences"
                    [label]="'_Flexible.WidgetConfiguration.Reference' | lgTranslate"
                >
                    <lg-dropdown
                        [definition]="_referenceDropdown()"
                        [current]="_getFirstEnabledReference()"
                        (currentChange)="_setFirstEnabledReference($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.Icon' | lgTranslate">
                    <lg-dropdown [definition]="_iconDropdown" [(current)]="_icon" />
                </lgflex-form-row>
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
</lgflex-dialog>
