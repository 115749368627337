<lgflex-dialog
    (closeAction)="_close()"
    (saveAction)="_save()"
    [isReadOnly]="false"
    [isValid]="_isConfigValid()"
    class="widget-configuration-dialog"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            (widgetDescriptionChange)="_widgetDescription.set($event)"
            (widgetNameChange)="_widgetName.set($event)"
            (widgetTooltipChange)="_widgetTooltip.set($event)"
            (widgetTooltipLinkChange)="_widgetTooltipLink.set($event)"
            (onDataSourceChange)="_onDataSourceChange($event)"
            (onSelectedReferencesChange)="
                this._pivotTableConfigurationService.setSelectedReferences($event)
            "
            (onReferenceSlotsValid)="_isReferenceSlotsValid.set($event)"
            [dataSourceCode]="_pivotTableConfigurationService.dataSourceCode()"
            [widgetDescription]="_widgetDescription()"
            [widgetName]="_widgetName()"
            [widgetTooltipLink]="_widgetTooltipLink()"
            [widgetTooltip]="_widgetTooltip()"
            [availableReferences]="_pivotTableConfigurationService.references()"
            [selectedReferences]="_pivotTableConfigurationService.selectedReferences()"
            [isReferenceSlotsValid]="_isReferenceSlotsValid()"
        />
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-dialog-grid>
            <lgflex-dialog-grid-column class="lgflex-pivot-table-configuration-column">
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Title" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <lg-placeholder name="pivotLevelsSelector.dropdownButtonContainer" />
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-table-dimensions-selector
                            #tableDimensionSelector
                            (dimensionSelectChange)="_onDimensionSelect($event)"
                            (tableSelectChange)="_onTableSelect($any($event))"
                            (tablesConfigChange)="_onTablesConfigChange($any($event))"
                            [availableDimensions]="_availableLevels()"
                            [defaultTableRowConfig]="defaultTableRowConfig"
                            [hideBorder]="true"
                            [tablesConfig]="$any(_tablesConfig())"
                        />
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Columns" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <lg-placeholder name="groupsValuesSelector.dropdownButtonContainer" />
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-pivot-table-columns-groups
                            #columnsGroups
                            (groupSelectChange)="_onGroupSelect($event)"
                            (groupsConfigChange)="_onGroupsConfigChange($event)"
                            (valueSelectChange)="_onValueSelect($event)"
                            [hideBorder]="true"
                            [allAvailableFields]="_columnsAvailableValues()"
                            [groupsConfig]="_groupsConfig()"
                            buttonPlaceholderName="groupsValuesSelector.dropdownButtonContainer"
                        />
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
            <lgflex-dialog-grid-column class="lgflex-pivot-table-configuration-column">
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Properties" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        @if (_currentGroup) {
                            <lgflex-pivot-table-columns-group-properties
                                (currentGroupUpdate)="_onCurrentGroupUpdate($event)"
                                [currentGroup]="_currentGroup"
                                [referenceDropdown]="_referenceDropdown"
                            />
                        }
                        @if (_currentValue) {
                            <lgflex-pivot-table-value-column-properties
                                (currentValueUpdate)="_onCurrentValueUpdate($event)"
                                [areReferencesAllowed]="_areReferencesAllowed"
                                [currentValue]="_currentValue"
                                [fieldsDropdownDefinition]="_fieldsDropdownDefinition()"
                                [pivotWidgetTypeDropdown]="_pivotWidgetTypeDropdown"
                                [referenceDropdown]="_referenceDropdown"
                                [schemaLookup]="_schemaLookup"
                            />
                        }
                        @if (_currentTable()) {
                            <lgflex-pivot-table-level-properties
                                (levelUpdate)="_onTableChange($event)"
                                [level]="_currentTable()"
                                [orderByItemDropdown]="_levelSortingDropdown()"
                            />
                        }
                        @if (_currentDimension()) {
                            <lgflex-pivot-table-dimension-properties
                                (dimensionUpdate)="_onDimensionUpdate($event)"
                                [dimension]="_currentDimension()"
                                [disableSorting]="_isDimensionSortingDisable()"
                                [orderByItemDropdown]="_dimensionSortingDropdown()"
                                [schemaTitle]="_getSchemaFieldTitle(_currentDimension().fieldId)"
                            />
                        }

                        @if (
                            !_currentColumn &&
                            !_currentTable &&
                            !_currentDimension &&
                            !_currentGroup &&
                            !_currentValue
                        ) {
                            <div style="text-align: center; line-height: 100px">
                                {{ ".SelectColumnText" | lgTranslate }}
                            </div>
                        }
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
        </lgflex-dialog-grid>
    </lgflex-dialog-tab>
</lgflex-dialog>
