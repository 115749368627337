import { Injectable, signal, WritableSignal } from "@angular/core";
import { DataSource, IFlexDataSources } from "./flex-data-sources.types";

@Injectable()
export class StaticDataSourceService implements IFlexDataSources {
    private _rootUrl: string | undefined;
    private _sources: DataSource[] | undefined;

    private _dataSourcesSignal: WritableSignal<DataSource[]> = signal([]);
    public dataSourcesSignal = this._dataSourcesSignal.asReadonly();

    configure(rootUrl: string): void {
        this._rootUrl = rootUrl;
        this._sources = [
            {
                code: "defaultCode",
                name: null,
                nameLc: "APP._.Default",
                groupCode: null,
                groupName: null,
                groupNameLc: null,
                rootUrl
            }
        ];
        this._dataSourcesSignal.set(this._sources);
    }

    get dataSources(): DataSource[] {
        if (this._sources === undefined)
            throw Error("Data sources are not configured yet. Call configure() first.");

        return this._sources;
    }
}
