import { inject, Injectable, ViewContainerRef } from "@angular/core";
import { DialogWidgetConfigurator } from "../../types";
import { BubbleChartWidgetConfig } from "./bubble-chart-widget.types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { BubbleChartConfigurationDialog } from "./components/bubble-chart-configuration-dialog/bubble-chart-configuration-dialog.component";
import { validateChartWidgetConfigBase } from "../../components/base/chart-configuration-dialog-base";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";

@Injectable()
export class BubbleChartWidgetConfigurator
    implements DialogWidgetConfigurator<BubbleChartWidgetConfig>
{
    private _layoutDataSourceService = inject(FlexibleLayoutDataSourcesService);
    constructor(
        private _flexDataClient: FlexDataClientService,
        private _pageReferences: PageReferencesService,
        private _dialog: BubbleChartConfigurationDialog,
        viewContainerRef: ViewContainerRef
    ) {
        this._dialog = _dialog.bindViewContainerRef(viewContainerRef);
    }

    readonly type = "dialog";

    async show(config: BubbleChartWidgetConfig): Promise<BubbleChartWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            dataSource: config.dataSource ?? null,
            selectedReferences: config.selectedReferences ?? null,
            levels: config.levels,
            xAxisColumnFormula: config.xAxisColumnFormula,
            yAxisColumnFormula: config.yAxisColumnFormula,
            sizeDimension: config.sizeDimension,
            chartType: config.chartType
        });

        return {
            title: response.widgetName,
            description: response.widgetDescription,
            dataSource: response.dataSource,
            selectedReferences: response.selectedReferences,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            levels: response.levels,
            xAxisColumnFormula: response.xAxisColumnFormula,
            yAxisColumnFormula: response.yAxisColumnFormula,
            sizeDimension: response.sizeDimension,
            columns: response.columns,
            chartType: response.chartType
        };
    }

    validate(config: BubbleChartWidgetConfig): boolean {
        let scheme = this._flexDataClient.scheme;
        let references = this._pageReferences;
        if (
            !!config.dataSource &&
            config.dataSource !== this._layoutDataSourceService.defaultLayoutDataSourceCode()
        ) {
            scheme = this._layoutDataSourceService.getDataSourceScheme(config.dataSource);
            references = {
                isAllowed: () => true,
                slots: config.selectedReferences
            } as PageReferencesService;
        }

        return validateChartWidgetConfigBase<BubbleChartWidgetConfig>(config, references, scheme);
    }
}
