<div class="form-row--input form-row">
    <div class="form-row__label">{{ ".Title" | lgTranslate }}:</div>
    <div class="form-row__controls input-with-help-info">
        <input
            class="input input--wide"
            type="text"
            [(ngModel)]="column.title"
            (ngModelChange)="columnChange.emit(column)"
        />
        <lg-help-info
            header="{{ '.Title' | lgTranslate }}"
            description="{{
                '_Flexible.ConfigurationDialogCommon.WidgetNameHelpInfo' | lgTranslate
            }}"
        ></lg-help-info>
    </div>
</div>

<div class="form-row--input form-row">
    <div class="form-row__label">{{ ".Type" | lgTranslate }}:</div>
    <div class="form-row__controls">
        <lg-dropdown
            [definition]="_formulaTypeDropdownDef"
            [(current)]="column.formula"
            (currentChange)="_onFormulaTypeChange($event)"
        ></lg-dropdown>
    </div>
</div>

@for (formulaVar of _currentFormulaVariables; track $index) {
    @if (column.variables[formulaVar]; as formulaVariable) {
        <div class="form-row--input form-row">
            <div class="form-row__label" style="display: flex">
                {{ formulaVariable.variable | titlecase }}:
                <lg-dropdown
                    style="width: 130px; margin-left: 8px"
                    [definition]="_formulaInputTypeDropdownDef"
                    [(current)]="formulaVariable.type"
                    (currentChange)="_onColumnChanged(column)"
                    [itemTooltips]="true"
                />
            </div>
            @if (formulaVariable.type === "variable") {
                <div class="form-row__controls form-row__controls--flex">
                    <div class="form-row__control-column">
                        <lg-dropdown
                            class="formulaVariableDropdown"
                            [definition]="fieldsDropdownDefinition"
                            [(current)]="formulaVariable.field!"
                            [isValid]="formulaVariable.field != null"
                            (currentChange)="_onColumnChanged(column)"
                            [itemTooltips]="true"
                        />
                    </div>
                    <div class="form-row__control-column">
                        @if (areReferencesAllowed && referencesDropdownDefinition != null) {
                            <lg-dropdown
                                class="formulaVariableDropdown"
                                [definition]="referencesDropdownDefinition"
                                [current]="formulaVariable.reference!"
                                (currentChange)="
                                    formulaVariable.reference = $event; columnChange.emit(column)
                                "
                                [itemTooltips]="true"
                            />
                        }
                    </div>
                </div>
            }
            @if (formulaVariable.type === "constant") {
                <div class="form-row__controls form-row__controls--flex">
                    <div class="form-row__control-column">
                        <input
                            class="input input--wide"
                            type="number"
                            [(ngModel)]="formulaVariable.constant"
                            (ngModelChange)="_onColumnChanged(column)"
                            [required]="true"
                        />
                    </div>
                </div>
            }
        </div>
    }
}

@if (!noFormatting) {
    <lgflex-configuration-dialog-format-and-precision
        [(formatType)]="column.formatType"
        [(formatPrecision)]="column.formatPrecision"
        [(formatForceSign)]="column.formatForceSign"
        (formatTypeChange)="columnChange.emit(column)"
        (formatForceSignChange)="columnChange.emit(column)"
        (formatPrecisionChange)="columnChange.emit(column)"
    />
}
