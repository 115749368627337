<ng-container *lgPlaceholderContent="'slideout-panel'">
    <lg-filters-panel></lg-filters-panel>
</ng-container>

<lg-content-grid [columnMajor]="true">
    <lg-content-grid-group id="group1" [size]="12">
        <lg-content-grid-item
            class="flexcol"
            id="item1"
            [lgLoaderOverlay]="_isPageBlockLoading$('data', 'data') | async"
        >
            <div class="lg-panel__header">
                <span
                    class="lg-panel__header-button"
                    tabindex="0"
                    [class.lg-panel__header-button--active]="_currentTab === 0"
                    (keyup.enter)="_setCurrentTab(0)"
                    (click)="_setCurrentTab(0)"
                >
                    {{ ".ExportsTab" | lgTranslate }} </span
                ><span
                    class="lg-panel__header-button"
                    tabindex="1"
                    [class.lg-panel__header-button--active]="_currentTab === 1"
                    (keyup.enter)="_setCurrentTab(1)"
                    (click)="_setCurrentTab(1)"
                >
                    {{ ".QueueTab" | lgTranslate }}
                </span>
            </div>

            <div class="lg-block--table">
                <div class="lg-panel__body flexcol">
                    <div class="flexcol" *ngIf="_currentTab === 0">
                        <lg-col-definition #_mainPivotColumns columnClass="table__column">
                            <lg-col-row id="header">
                                <lg-col
                                    class="crop"
                                    id="main"
                                    width="482"
                                    type="headerLevels"
                                ></lg-col>
                                <lg-col class="crop" id="status" width="28" type="icons"></lg-col>
                                <lg-col class="" id="duration" width="150"></lg-col>
                                <lg-col class="" id="finishedAt" width="200"></lg-col>
                                <lg-col class="crop" id="requestedBy" width="*"></lg-col>
                                <lg-col class="" id="spacer" width="*"></lg-col>
                            </lg-col-row>
                            <lg-col-row id="level0">
                                <lg-col class="" id="_col0" width="24" type="expand"></lg-col>
                                <lg-col class="crop" id="main" width="*"></lg-col>
                            </lg-col-row>
                            <lg-col-row id="level1">
                                <lg-col class="" id="_col0" width="24" type="empty"></lg-col>
                                <lg-col class="" id="_col1" width="24" type="expand"></lg-col>
                                <lg-col class="crop" id="main" width="430"></lg-col>
                                <lg-col class="crop" id="status" width="28" type="icons"></lg-col>
                                <lg-col class="" id="duration" width="150"></lg-col>
                                <lg-col class="" id="finishedAt" width="200"></lg-col>
                                <lg-col class="crop" id="requestedBy" width="*"></lg-col>
                                <lg-col class="" id="spacer" width="*"></lg-col>
                            </lg-col-row>
                            <lg-col-row id="level2">
                                <lg-col class="" id="_col0" width="24" type="empty"></lg-col>
                                <lg-col class="" id="_col1" width="24" type="empty"></lg-col>
                                <lg-col class="crop" id="main" width="418"></lg-col>
                                <lg-col class="crop" id="status" width="28" type="icons"></lg-col>
                                <lg-col class="" id="duration" width="150"></lg-col>
                                <lg-col class="" id="finishedAt" width="200"></lg-col>
                                <lg-col class="crop" id="requestedBy" width="*"></lg-col>
                                <lg-col class="" id="spacer" width="*"></lg-col>
                            </lg-col-row>
                        </lg-col-definition>
                        <lg-rectangle
                            #_mainPivotWrapper
                            [auditTime]="0"
                            [class.flexcol.flexcol--full]="true"
                        >
                            <lg-pivot-table
                                #_mainPivotView
                                [definition]="_mainPivot.definition"
                                [build]="false"
                                [passive]="true"
                                [data]="_mainPivot.all"
                                [filteredData]="_mainPivot.filtered"
                                [totals]="_mainPivot.totals"
                                [(orderBy)]="_mainPivot.orderBy"
                                (orderByChange)="_mainPivot.refilter()"
                                [pivotContext]="this"
                                [lgColDefinition]="_mainPivotColumns"
                                [lgColDefinitionWidth]="_mainPivotWrapper.width - 10"
                                [stretchable]="true"
                            >
                                <lg-pivot-table-header
                                    lgColRow="header"
                                    *lgPivotTableHeaderDef="let maxVisibleLevel = maxVisibleLevel"
                                >
                                    <lg-pivot-table-level-headers
                                        lgCol="main"
                                        [levels]="[
                                            {
                                                header: '.Group' | lgTranslate,
                                                orderBy: 'categoryName'
                                            },
                                            { header: '.Page' | lgTranslate },
                                            {
                                                header: '.Export' | lgTranslate,
                                                orderBy: '-createdAt'
                                            }
                                        ]"
                                    ></lg-pivot-table-level-headers>
                                    <div lgCol="status"></div>
                                    <div lgCol="duration">{{ ".Duration" | lgTranslate }}</div>
                                    <div
                                        lgCol="finishedAt"
                                        lgPivotTableSortByColumn="finishedAt"
                                        [sortByLevel]="2"
                                    >
                                        {{ ".FinishedAt" | lgTranslate }}
                                    </div>
                                    <div lgCol="requestedBy">
                                        {{ ".RequestedBy" | lgTranslate }}
                                    </div>
                                    <div lgCol="spacer"></div>
                                </lg-pivot-table-header>
                                <lg-pivot-table-row
                                    class="table__row"
                                    lgColRow="level0"
                                    *lgPivotTableRowDef="
                                        let row1;
                                        onLevel: 'row1';
                                        let rows = items
                                    "
                                    [toggleOnClick]="true"
                                    [ngClass]="{ 'table__row--clickable': true }"
                                >
                                    <div class="center" lgCol="_col0">
                                        <lg-pivot-table-expand
                                            title="{{ 'FW.CLICK_TO_EXPAND' | lgTranslate }}"
                                        ></lg-pivot-table-expand>
                                    </div>
                                    <div
                                        lgCol="main"
                                        lgColSpan="6"
                                        [innerHTML]="row1.categoryName"
                                    ></div>
                                </lg-pivot-table-row>
                                <lg-pivot-table-row
                                    class="table__row"
                                    lgColRow="level1"
                                    *lgPivotTableRowDef="
                                        let row2;
                                        onLevel: 'row2';
                                        let rows = items
                                    "
                                    [toggleOnClick]="row2.isExpandable"
                                    [ngClass]="{ 'table__row--clickable': row2.isExpandable }"
                                >
                                    <lg-pivot-table-empty-column
                                        lgCol="_col0"
                                        [level]="0"
                                    ></lg-pivot-table-empty-column>
                                    <div class="center" lgCol="_col1">
                                        <lg-pivot-table-expand
                                            title="{{ 'FW.CLICK_TO_EXPAND' | lgTranslate }}"
                                            *ngIf="row2.isExpandable"
                                        ></lg-pivot-table-expand>
                                    </div>
                                    <div lgCol="main" [innerHTML]="row2.exportName"></div>
                                    <div lgCol="status">
                                        <lg-icon
                                            class="table__column-icon clickable"
                                            *ngIf="row2.status == null"
                                            icon="icon-export"
                                            [clickable]="true"
                                            [lgTooltip]="'.RequestExportButtonHint' | lgTranslate"
                                            (click)="_requestExport($event, row2)"
                                        ></lg-icon>

                                        <lg-icon
                                            class="icon--rotating table__column-icon"
                                            *ngIf="row2.status === 2"
                                            icon="icon-created-not-started-state"
                                            [lgTooltip]="'.ExportInProgressHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon"
                                            *ngIf="row2.status === 1"
                                            icon="icon-time"
                                            [lgTooltip]="'.QueuedExportHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon icon--color-red clickable"
                                            *ngIf="row2.status === 5"
                                            icon="icon-warning"
                                            (click)="_showError(row2)"
                                        ></lg-icon>
                                    </div>
                                    <div lgCol="duration"></div>
                                    <div lgCol="finishedAt"></div>
                                    <div lgCol="requestedBy"></div>
                                    <div lgCol="spacer"></div>
                                </lg-pivot-table-row>
                                <lg-pivot-table-row
                                    class="table__row"
                                    lgColRow="level2"
                                    *lgPivotTableRowDef="
                                        let row3;
                                        onLevel: 'row3';
                                        let rows = items
                                    "
                                    [ngClass]="{}"
                                >
                                    <lg-pivot-table-empty-column
                                        lgCol="_col0"
                                        [level]="0"
                                    ></lg-pivot-table-empty-column>
                                    <lg-pivot-table-empty-column
                                        lgCol="_col1"
                                        [level]="1"
                                    ></lg-pivot-table-empty-column>
                                    <div
                                        lgCol="main"
                                        [innerHTML]="row3.createdAt | date: 'short'"
                                    ></div>
                                    <div lgCol="status">
                                        <lg-icon
                                            class="table__column-icon clickable"
                                            *ngIf="row3.status === 3"
                                            icon="icon-download"
                                            [clickable]="true"
                                            [lgTooltip]="'.DownloadExportButtonHint' | lgTranslate"
                                            (click)="_downloadExportDo(row3.exportId)"
                                        ></lg-icon>

                                        <lg-icon
                                            class="icon--rotating table__column-icon"
                                            *ngIf="row3.status === 2"
                                            icon="icon-created-not-started-state"
                                            [lgTooltip]="'.ExportInProgressHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon"
                                            *ngIf="row3.status === 1"
                                            icon="icon-time"
                                            [lgTooltip]="'.QueuedExportHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon icon--color-red clickable"
                                            *ngIf="row3.status === 5"
                                            icon="icon-warning"
                                            (click)="_showError(row3)"
                                        ></lg-icon>
                                    </div>
                                    <div lgCol="duration">
                                        <span
                                            *ngIf="row3.finishedAt != null"
                                            [innerHTML]="
                                                row3.finishedAt - row3.startedAt | fmtMsToTime
                                            "
                                        ></span>
                                        <span *ngIf="row3.finishedAt == null"
                                            ><app-counter
                                                [id]="row3.exportId"
                                                [startedAt]="row3.startedAt"
                                            ></app-counter
                                        ></span>
                                    </div>
                                    <div
                                        lgCol="finishedAt"
                                        [innerHTML]="row3.finishedAt | date: 'short'"
                                    ></div>
                                    <div
                                        lgCol="requestedBy"
                                        [innerHTML]="row3.requestingUser"
                                    ></div>
                                    <div lgCol="spacer"></div>
                                </lg-pivot-table-row>
                            </lg-pivot-table>
                            <lg-pivot-instance-sync
                                [pivotInstance]="_mainPivot"
                                [component]="_mainPivotView"
                            ></lg-pivot-instance-sync>
                        </lg-rectangle>
                    </div>
                    <div class="flexcol" *ngIf="_currentTab === 1">
                        <lg-col-definition #_queuePivotColumns columnClass="table__column">
                            <lg-col-row id="header">
                                <lg-col class="crop" id="main" width="450"></lg-col>
                                <lg-col class="crop" id="status" width="28" type="icons"></lg-col>
                                <lg-col class="" id="createdAt" width="200"></lg-col>
                                <lg-col class="" id="duration" width="150"></lg-col>
                                <lg-col class="" id="finishedAt" width="200"></lg-col>
                                <lg-col class="" id="requestedBy" width="*"></lg-col>
                                <lg-col class="" id="spacer" width="*"></lg-col>
                            </lg-col-row>
                            <lg-col-row id="level0">
                                <lg-col class="crop" id="main" width="450"></lg-col>
                                <lg-col class="crop" id="status" width="28" type="icons"></lg-col>
                                <lg-col class="" id="createdAt" width="200"></lg-col>
                                <lg-col class="" id="duration" width="150"></lg-col>
                                <lg-col class="" id="finishedAt" width="200"></lg-col>
                                <lg-col class="" id="requestedBy" width="*"></lg-col>
                                <lg-col class="" id="spacer" width="*"></lg-col>
                            </lg-col-row>
                        </lg-col-definition>
                        <lg-rectangle
                            #_queuePivotWrapper
                            [auditTime]="0"
                            [class.flexcol.flexcol--full]="true"
                        >
                            <lg-pivot-table
                                #_queuePivotView
                                [definition]="_queuePivot.definition"
                                [build]="false"
                                [passive]="true"
                                [data]="_queuePivot.all"
                                [filteredData]="_queuePivot.filtered"
                                [totals]="_queuePivot.totals"
                                [(orderBy)]="_queuePivot.orderBy"
                                (orderByChange)="_queuePivot.refilter()"
                                [pivotContext]="this"
                                [lgColDefinition]="_queuePivotColumns"
                                [lgColDefinitionWidth]="_queuePivotWrapper.width - 10"
                                [stretchable]="true"
                            >
                                <lg-pivot-table-header
                                    lgColRow="header"
                                    *lgPivotTableHeaderDef="let maxVisibleLevel = maxVisibleLevel"
                                >
                                    <div
                                        lgCol="main"
                                        *ngIf="maxVisibleLevel === 0"
                                        lgPivotTableSortByColumn="-createdAt"
                                        [sortByLevel]="0"
                                    >
                                        {{ ".Export" | lgTranslate }}
                                    </div>
                                    <div lgCol="status"></div>
                                    <div lgCol="createdAt">{{ ".CreatedAt" | lgTranslate }}</div>
                                    <div lgCol="duration">{{ ".Duration" | lgTranslate }}</div>
                                    <div
                                        lgCol="finishedAt"
                                        lgPivotTableSortByColumn="finishedAt"
                                        [sortByLevel]="maxVisibleLevel"
                                    >
                                        {{ ".FinishedAt" | lgTranslate }}
                                    </div>
                                    <div lgCol="requestedBy">
                                        {{ ".RequestedBy" | lgTranslate }}
                                    </div>
                                    <div lgCol="spacer"></div>
                                </lg-pivot-table-header>
                                <lg-pivot-table-row
                                    class="table__row"
                                    lgColRow="level0"
                                    *lgPivotTableRowDef="
                                        let row1;
                                        onLevel: 'row1';
                                        let rows = items
                                    "
                                    [ngClass]="{}"
                                >
                                    <div
                                        lgCol="main"
                                        [innerHTML]="_renderNameFromTypeCode(row1.exportTypeCode)"
                                    ></div>
                                    <div lgCol="status">
                                        <lg-icon
                                            class="icon--rotating table__column-icon"
                                            *ngIf="row1.status === 2"
                                            icon="icon-created-not-started-state"
                                            [lgTooltip]="'.ExportInProgressHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon"
                                            *ngIf="row1.status === 1"
                                            icon="icon-time"
                                            [lgTooltip]="'.QueuedExportHint' | lgTranslate"
                                        ></lg-icon>

                                        <lg-icon
                                            class="table__column-icon icon--color-red clickable"
                                            *ngIf="row1.status === 5"
                                            icon="icon-warning"
                                            (click)="_showError(row1)"
                                        ></lg-icon>
                                    </div>
                                    <div
                                        lgCol="createdAt"
                                        [innerHTML]="row1.createdAt | date: 'short'"
                                    ></div>
                                    <div lgCol="duration">
                                        <span
                                            *ngIf="row1.finishedAt != null"
                                            [innerHTML]="
                                                row1.finishedAt - row1.startedAt | fmtMsToTime
                                            "
                                        ></span>
                                        <span *ngIf="row1.finishedAt == null">
                                            <app-counter
                                                [id]="row1.exportId"
                                                [startedAt]="row1.startedAt"
                                            ></app-counter>
                                        </span>
                                    </div>
                                    <div
                                        lgCol="finishedAt"
                                        [innerHTML]="row1.finishedAt | date: 'short'"
                                    ></div>
                                    <div
                                        lgCol="requestedBy"
                                        [innerHTML]="row1.requestingUser"
                                    ></div>
                                    <div lgCol="spacer"></div>
                                </lg-pivot-table-row>
                            </lg-pivot-table>
                            <lg-pivot-instance-sync
                                [pivotInstance]="_queuePivot"
                                [component]="_queuePivotView"
                            ></lg-pivot-instance-sync>
                        </lg-rectangle>
                    </div>
                </div>
            </div>
        </lg-content-grid-item>
    </lg-content-grid-group>
</lg-content-grid>
