import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    inject,
    input,
    Input,
    output,
    Output,
    Signal
} from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    IFlexiblePageAuthorization,
    LG_FLEX_PAGE_AUTHORIZATION
} from "../../../services/flexible-page-authorization/flexible-page-authorization.types";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import {
    IFlexDataSources,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../../../services/flex-data-sources/flex-data-sources.types";
import { translateNullableName } from "../../../utilities";
import * as _ from "lodash-es";
import { ReferenceSlotsEditorDialog } from "../../flexible-layout-editor-dialog/components/reference-slots-editor-dialog/reference-slots-editor-dialog.component";
import { FlexibleLayoutDataSourcesService } from "../../../services/flexible-layout-data-sources";
import { ReferenceInfo, ReferenceSlot } from "../../../types";

@Component({
    selector: "lgflex-widget-configuration-section-information",
    templateUrl: "./widget-configuration-section-information.component.html",
    styleUrls: ["./widget-configuration-section-information.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._PivotConfigurationDialog")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetConfigurationSectionInformationComponent {
    private _flexPageAuthorization: IFlexiblePageAuthorization = inject(LG_FLEX_PAGE_AUTHORIZATION);
    private _dataSourcesService: IFlexDataSources = inject(LG_FLEX_DATA_SOURCES_SERVICE);
    private _layoutDataSourcesService = inject(FlexibleLayoutDataSourcesService);
    private _referenceSlotsEditorDialog = inject(ReferenceSlotsEditorDialog);

    private readonly _lgTranslate = inject(LgTranslateService);

    @Input() widgetName: string | undefined;
    @Output() readonly widgetNameChange = new EventEmitter<string>();

    @Input() widgetDescription: string | undefined;
    @Output() readonly widgetDescriptionChange = new EventEmitter<string>();

    @Input() widgetTooltip: string | undefined;
    @Output() readonly widgetTooltipChange = new EventEmitter<string>();

    @Input() widgetTooltipLink: string | undefined;
    @Output() readonly widgetTooltipLinkChange = new EventEmitter<string>();

    isReferenceSlotsValid = input<boolean>();
    availableReferences = input<ReferenceInfo[]>();
    selectedReferences = input<ReferenceSlot[]>();
    dataSourceCode = input<string | null>(undefined);
    onDataSourceChange = output<string>();
    onSelectedReferencesChange = output<ReferenceSlot[]>();
    onReferenceSlotsValid = output<boolean>();

    isDefaultDataSource = computed(
        () => this._layoutDataSourcesService.defaultLayoutDataSourceCode() === this.dataSourceCode()
    );

    protected _defaultDataSourceCode = computed(() =>
        this._layoutDataSourcesService.defaultLayoutDataSourceCode()
    );

    protected _canEditWidgetTooltipLink = this._flexPageAuthorization.canEditWidgetTooltipLink();

    protected _dataSourcesDropdown: Signal<IDropdownDefinition<string>> = computed(() => {
        return {
            groupId: "groupCode",
            groupName: "groupName",
            entryId: "code",
            entryName: "name",
            groups: _.chain(this._dataSourcesService.dataSourcesSignal())
                .groupBy("groupCode")
                .map(dsg => ({
                    groupCode: dsg[0].groupCode,
                    groupName: translateNullableName(
                        this._lgTranslate,
                        dsg[0].groupName,
                        dsg[0].groupNameLc
                    ),
                    entries: dsg.map(ds => ({
                        code: ds.code,
                        name: translateNullableName(this._lgTranslate, ds.name, ds.nameLc)
                    }))
                }))
                .value()
        };
    });

    protected async _editReferences(): Promise<void> {
        const response = await this._referenceSlotsEditorDialog.show({
            numReferences: this.selectedReferences().length,
            referenceSlots: this.selectedReferences(),
            availableReferences: this.availableReferences(),
            widgets: []
        });

        this.onSelectedReferencesChange.emit(response.referenceSlots);
        this.onReferenceSlotsValid.emit(true);
    }

    protected onDataSourceChanged(dataSourceCode: string): void {
        this.onDataSourceChange.emit(dataSourceCode);
        this.onReferenceSlotsValid.emit(
            this.dataSourceCode() !== null && !this.isDefaultDataSource()
        );
    }
}
