import { inject, Pipe, PipeTransform } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Pipe({
    name: "fmtMsToTime"
})
export class FormatMsToTimePipe implements PipeTransform {
    protected _lgTranslate = inject(LgTranslateService);

    transform(ms: number, displaySeconds = false): string {
        if (ms == null) return "-";

        const str = (translation: string): string => {
            const translationPfx = "FADP._Exports.Counter.";
            return this._lgTranslate.translate(translationPfx + translation);
        };

        const hrs = Math.floor(ms / 1000 / 60 / 60);
        const mins = displaySeconds
            ? Math.floor(ms / 1000 / 60) % 60
            : Math.ceil(ms / 1000 / 60) % 60;
        const secs = Math.ceil(ms / 1000) % 60;

        return (
            (hrs > 0
                ? hrs +
                  " " +
                  str(hrs === 1 ? "Hr" : "Hrs") +
                  " " +
                  mins +
                  " " +
                  str(mins === 1 ? "Min" : "Mins")
                : mins > 0
                  ? mins + " " + str(mins === 1 ? "Minute" : "Minutes")
                  : "") +
            (!displaySeconds ? "" : " " + secs + " " + str(secs === 1 ? "Second" : "Seconds"))
        );
    }
}
