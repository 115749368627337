import { PIVOT_TABLE_WIDGET_2 } from "../widgets/pivot-table/pivot-table-widget.configuration.types";
import { CHART_WIDGET } from "../widgets/chart/chart-widget.configuration.types";
import { ICON_CHART_WIDGET } from "../widgets/icon-chart/types/icon-chart-widget.types";
import { CROSSTAB_WIDGET } from "../widgets/crosstab-widget/crosstab-widget.types";
import { BUBBLE_CHART_WIDGET } from "../widgets/bubble-chart/bubble-chart-widget.types";
import { PIE_CHART_WIDGET } from "../widgets/pie-chart/pie-chart-widget.types";

export const WIDGET_ICONS_MAP = {
    [CHART_WIDGET]: "icon-bar-chart",
    [PIVOT_TABLE_WIDGET_2]: "icon-table",
    [CROSSTAB_WIDGET]: "icon-table",
    [BUBBLE_CHART_WIDGET]: "icon-dot-indicator",
    [ICON_CHART_WIDGET]: "icon-user",
    [PIE_CHART_WIDGET]: "icon-pie-chart"
};
