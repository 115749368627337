import { inject, Injectable, INJECTOR } from "@angular/core";
import {
    PivotTableGroupOrColumn,
    PivotTableWidgetConfig
} from "./pivot-table-widget.configuration.types";
import { PivotTableConfigurationDialog } from "./components/pivot-table-configuration-dialog/pivot-table-configuration-dialog.component";
import { validatePivotTableConfiguration } from "./components/pivot-table-configuration-dialog/pivot-table-configuration-validation";
import { DialogWidgetConfigurator } from "../../types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";

@Injectable()
export class PivotTableWidgetConfigurator
    implements DialogWidgetConfigurator<PivotTableWidgetConfig>
{
    private _injector = inject(INJECTOR);
    public _lgTranslate = inject(LgTranslateService);
    private _flexDataClient = inject(FlexDataClientService);
    private _dialog = inject(PivotTableConfigurationDialog).bindViewContainerRef();
    private _widgetTypes = inject(WidgetTypesRegistry);
    private _pageReferences = inject(PageReferencesService);
    private _layoutDataSourceService = inject(FlexibleLayoutDataSourcesService);

    readonly type = "dialog";

    async show(config: PivotTableWidgetConfig): Promise<PivotTableWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            dataSource: config.dataSource ?? null,
            selectedReferences: config.selectedReferences ?? null,
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            columns: config.columns as PivotTableGroupOrColumn[],
            tablesConfig: config.tablesConfig,
            pageReferences: this._pageReferences
        });

        return {
            title: response.widgetName,
            dataSource: response.dataSource,
            description: response.widgetDescription,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            selectedReferences: response.selectedReferences,
            columns: response.columns,
            tablesConfig: response.tablesConfig
        };
    }

    validate(config: PivotTableWidgetConfig): boolean {
        let scheme = this._flexDataClient.scheme;
        let references = this._pageReferences;
        if (
            !!config.dataSource &&
            config.dataSource !== this._layoutDataSourceService.defaultLayoutDataSourceCode()
        ) {
            scheme = this._layoutDataSourceService.getDataSourceScheme(config.dataSource);
            references = {
                isAllowed: () => true,
                slots: config.selectedReferences
            } as PageReferencesService;
        }

        return validatePivotTableConfiguration(
            this._lgTranslate,
            this._injector,
            config,
            references,
            scheme,
            this._widgetTypes
        );
    }
}
