import { inject, Injectable, ViewContainerRef } from "@angular/core";
import { DialogWidgetConfigurator } from "../../types";
import { ChartWidgetConfig } from "./chart-widget.configuration.types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { VerticalChartConfigurationDialog } from "./components/chart-configuration-dialog/chart-configuration-dialog.component";
import { validateChartConfiguration } from "./components/chart-configuration-dialog/chart-configuration-validation";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";

@Injectable()
export class ChartWidgetConfigurator implements DialogWidgetConfigurator<ChartWidgetConfig> {
    private _layoutDataSourceService = inject(FlexibleLayoutDataSourcesService);
    private _flexDataClient = inject(FlexDataClientService);
    private _pageReferences = inject(PageReferencesService);
    private _dialog = inject(VerticalChartConfigurationDialog).bindViewContainerRef();

    readonly type = "dialog";

    async show(config: ChartWidgetConfig): Promise<ChartWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            levels: config.levels,
            columns: config.columns,
            allowMultipleReferences: true,
            totalsAsTopLevel: config.totalsAsTopLevel ?? false,
            ignoreOwnFilters: config.ignoreOwnFilters ?? false,
            formatType: config.formatType,
            formatPrecision: config.formatPrecision,
            formatForceSign: config.formatForceSign ?? false,
            labels: config.labels,
            chartOrientation: config.chartOrientation,
            tablesConfig: config.tablesConfig,
            dataSource: config.dataSource ?? null,
            selectedReferences: config.selectedReferences ?? null
        });

        return {
            title: response.widgetName,
            description: response.widgetDescription,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            columns: response.columns,
            totalsAsTopLevel: response.totalsAsTopLevel,
            ignoreOwnFilters: response.ignoreOwnFilters,
            formatType: response.formatType,
            formatPrecision: response.formatPrecision,
            formatForceSign: response.formatForceSign,
            labels: response.labels,
            chartOrientation: response.chartOrientation,
            tablesConfig: response.tablesConfig,
            levels: response.levels,
            dataSource: response.dataSource,
            selectedReferences: response.selectedReferences
        };
    }

    validate(config: ChartWidgetConfig): boolean {
        let scheme = this._flexDataClient.scheme;
        let references = this._pageReferences;
        if (
            !!config.dataSource &&
            config.dataSource !== this._layoutDataSourceService.defaultLayoutDataSourceCode()
        ) {
            scheme = this._layoutDataSourceService.getDataSourceScheme(config.dataSource);
            references = {
                isAllowed: () => true,
                slots: config.selectedReferences
            } as PageReferencesService;
        }

        return validateChartConfiguration(config, references, scheme);
    }
}
