import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Component, computed, ElementRef, Inject, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { ILgFormatterOptions, LgConsole } from "@logex/framework/core";
import {
    ComboFilterRenderer2,
    IComboFilter2Definition,
    LgFilterSet
} from "@logex/framework/lg-filterset";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { INodeStateStore, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";

import {
    ChartClickEvent,
    LgBubbleChartComponent,
    LgBubbleChartDatum,
    LgBubbleChartTooltipContext
} from "@logex/framework/lg-charts";

import { FlexiblePivotDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-definition-factory";
import {
    ExportFormat,
    FieldInfo,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    PivotTableColumnFormula,
    Widget,
    WidgetUsage
} from "../../types";
import { ChartWidgetBaseComponent } from "../base/chart-widget-base/chart-widget-base.component";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { FlexibleDatasetDataArgumentsGetter } from "../../services/flexible-dataset";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import {
    cloneElementWithStyles,
    getExportFilename
} from "../../services/widget-export/widget-export-utils";
import { exportSvg, exportSvgToPng } from "../../services/widget-export/widget-export-image";
import { BubbleChartWidgetConfigurator } from "./bubble-chart-widget-configurator";
import {
    BUBBLE_CHART_WIDGET,
    BubbleChartDimensionFormatter,
    BubbleChartType,
    BubbleChartWidgetConfig,
    BubbleChartWidgetState
} from "./bubble-chart-widget.types";
import { translateFormulaColumn, translateNullableName } from "../../utilities";
import { getItemNameFromField } from "../../utilities/getItemNameFromField";
import { ModReferenceVariablePipe } from "../../pipes/mod-reference-variable.pipe";

@Component({
    selector: "lgflex-bubble-chart-widget",
    templateUrl: "./bubble-chart-widget.component.html",
    styleUrls: ["./bubble-chart-widget.component.scss"],
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    viewProviders: [...useTranslationNamespace("_Flexible.BubbleChartWidget")]
})
@Widget({
    id: BUBBLE_CHART_WIDGET,
    nameLc: "_Flexible.BubbleChartWidget.WidgetTitle",
    usage: WidgetUsage.Page,
    configurator: BubbleChartWidgetConfigurator,
    configVersion: 2
})
export class BubbleChartWidgetComponent extends ChartWidgetBaseComponent<BubbleChartWidgetConfig> {
    constructor(
        promptDialog: LgPromptDialog,
        lgTranslate: LgTranslateService,
        lgConsole: LgConsole,
        flexDataClient: FlexDataClientService,
        filters: LgFilterSet<any, any>,
        pageReferences: PageReferencesService,
        widgetTypes: WidgetTypesRegistry,
        pivotInstanceFactory: LgPivotInstanceFactory,
        flexiblePivotFactory: FlexiblePivotDefinitionFactory,
        @Inject(LG_FLEX_LAYOUT_WIDGET_HOST) widgetHost: IWidgetHost,
        configurator: BubbleChartWidgetConfigurator,
        exportService: WidgetExportExcelService,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>,
        private _referenceVariablePipe: ModReferenceVariablePipe
    ) {
        super(
            promptDialog,
            lgTranslate,
            lgConsole,
            flexDataClient,
            filters,
            pageReferences,
            widgetTypes,
            pivotInstanceFactory,
            flexiblePivotFactory,
            widgetHost,
            configurator as any,
            exportService
        );

        this._filters.onChanged
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => this._extractFilteredFields());
    }

    // ----------------------------------------------------------------------------------
    @ViewChild("rect", { read: ElementRef }) rectEl: ElementRef | undefined;
    @ViewChild("lgBubbleChart") lgBubbleChart!: LgBubbleChartComponent;

    _tooltipType!: LgBubbleChartTooltipContext;

    private _chartPath: Array<number | string> = [];
    _chartCurrentLevel = 0;
    _chartCurrentLevelKeyField: FieldInfo | undefined;
    _chartLevelData: LgBubbleChartDatum[] = [];
    _chartType: BubbleChartType = "classic";

    _filteredItems: Array<string | number> = [];

    private _chartReferenceSlots: Set<number> = new Set();
    private _chartReferenceNames = new Map<number, string>();

    _xAxisTitle = "";
    _yAxisTitle = "";
    _sizeTitle = "";

    _yAxisFormatterOptions: ILgFormatterOptions = {};
    _xAxisFormatterOptions: ILgFormatterOptions = {};
    _xDimensionFormatter: BubbleChartDimensionFormatter | undefined;
    _yDimensionFormatter: BubbleChartDimensionFormatter | undefined;
    _sizeDimensionFormatter: BubbleChartDimensionFormatter | undefined;

    protected _schemeLookup = computed(() => _.keyBy(this._scheme(), "field"));

    // ----------------------------------------------------------------------------------
    override async setConfig(config: BubbleChartWidgetConfig): Promise<void> {
        await super.setConfig(config);

        if (!this._isConfigValid) return;

        this._chartReferenceSlots = new Set(
            config.columns
                .map(x => {
                    if ("referenceIdx" in x) {
                        // PivotTableColumnDefault column
                        return [x.referenceIdx];
                    }
                    if ("variables" in x) {
                        // PivotTableColumnFormula column
                        return [
                            x.variables.a?.type === "constant" ? null : x.variables.a?.reference,
                            x.variables.b?.type === "constant" ? null : x.variables.b?.reference,
                            x.variables.c?.type === "constant" ? null : x.variables.c?.reference
                        ];
                    }
                    return null;
                })
                .flat()
                .filter((x): x is number => x != null)
        );
        this._prepareChartFormatters();
        this._chartType = config.chartType ?? "classic";
        this._prepareChartDimensionsNames();
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!this._isConfigValid) return;
        // Update the chart when filter changes
        this._filters.onChanged
            .pipe(takeUntil(this._destroyed$))
            .subscribe((filters: Array<string | number | symbol>) => {
                if (!_.isEmpty(_.intersection(filters, this._levels))) {
                    this._chartPrepareLevelData();
                }
            });

        if (this._pageReferences.isAllowed()) {
            this._prepareReferenceNames();
            this._prepareChartDimensionsNames();
            this._pageReferences.stateChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
                this._prepareReferenceNames();
                this._prepareChartDimensionsNames();
            });
        }
    }

    protected override _buildPivot(data: unknown[], state: INodeStateStore): void {
        super._buildPivot(data, state);
        this._chartPrepareLevelData();
    }

    private _prepareReferenceNames(): void {
        this._chartReferenceSlots.forEach(slot => {
            const ref = this._pageReferences.selectedReferences.find(x => x.slotIdx === slot);
            const name = translateNullableName(this._lgTranslate, ref?.name, ref?.nameLc);
            this._chartReferenceNames.set(slot, name);
        });
    }

    private _prepareChartFieldsNamesLc(
        chartFieldsNameLc: Map<string, string>,
        formula: PivotTableColumnFormula
    ): void {
        if (formula?.variables.a != null && formula.variables.a.type !== "constant")
            chartFieldsNameLc.set(
                formula.variables.a.field,
                this._schemeLookup()[formula.variables.a.field].nameLc ?? ""
            );
        if (formula?.variables.b != null && formula.variables.b.type !== "constant")
            chartFieldsNameLc.set(
                formula.variables.b.field,
                this._schemeLookup()[formula.variables.b.field].nameLc ?? ""
            );
        if (formula?.variables.c != null && formula.variables.c.type !== "constant")
            chartFieldsNameLc.set(
                formula.variables.c.field,
                this._schemeLookup()[formula.variables.c.field].nameLc ?? ""
            );
    }

    private _prepareChartDimensionsNames(): void {
        const chartFieldsNameLc: Map<string, string> = new Map<string, string>();
        this._prepareChartFieldsNamesLc(chartFieldsNameLc, this._config.xAxisColumnFormula);
        this._prepareChartFieldsNamesLc(chartFieldsNameLc, this._config.yAxisColumnFormula);

        this._xAxisTitle =
            this._config.xAxisColumnFormula.title !== undefined
                ? this._referenceTitleTransform(this._config.xAxisColumnFormula.title)
                : translateFormulaColumn(
                      this._lgTranslate,
                      this._config.xAxisColumnFormula,
                      chartFieldsNameLc,
                      this._chartReferenceNames
                  );
        this._yAxisTitle =
            this._config.yAxisColumnFormula.title !== undefined
                ? this._referenceTitleTransform(this._config.yAxisColumnFormula.title)
                : translateFormulaColumn(
                      this._lgTranslate,
                      this._config.yAxisColumnFormula,
                      chartFieldsNameLc,
                      this._chartReferenceNames
                  );

        this._sizeTitle = translateNullableName(
            this._lgTranslate,
            this._schemeLookup()[this._config.sizeDimension.field].name,
            this._schemeLookup()[this._config.sizeDimension.field].nameLc
        );

        if (this._pageReferences.isAllowed()) {
            if (
                this._isReferencesNull(this._config.xAxisColumnFormula) == null ||
                this._isReferencesNull(this._config.yAxisColumnFormula) == null ||
                this._config.sizeDimension.reference == null
            )
                throw Error("Dimension reference shouldn't be undefined.");
        }
    }

    _isReferencesNull(formula: PivotTableColumnFormula): boolean {
        return (
            (formula.variables.a?.type === "variable" && formula.variables.a?.reference == null) ||
            (formula.variables.b?.type === "variable" && formula.variables.b?.reference == null) ||
            (formula.variables.c?.type === "variable" && formula.variables.c?.reference == null)
        );
    }

    override getState(): BubbleChartWidgetState | null {
        if (!this._isConfigValid) return null;
        const table = super.getState();
        if (!table) return null;
        return {
            ...table,
            version: 2
        };
    }

    override setState(state: BubbleChartWidgetState): boolean {
        if (!super.setState(state)) return false;
        this._chartPrepareLevelData();
        return true;
    }

    override getWidgetType(): string {
        return BUBBLE_CHART_WIDGET;
    }

    // ----------------------------------------------------------------------------------
    // Chart
    private _prepareChartFormatters(): void {
        this._xDimensionFormatter = {
            format: this._config.xAxisColumnFormula.formatType,
            decimals: this._config.xAxisColumnFormula.formatPrecision,
            forceSign: this._config.xAxisColumnFormula.formatForceSign ?? false
        };
        this._xAxisFormatterOptions = {
            decimals: this._config.xAxisColumnFormula.formatPrecision,
            forceSign: this._config.xAxisColumnFormula.formatForceSign ?? false
        };
        this._yDimensionFormatter = {
            format: this._config.yAxisColumnFormula.formatType,
            decimals: this._config.yAxisColumnFormula.formatPrecision,
            forceSign: this._config.yAxisColumnFormula.formatForceSign ?? false
        };
        this._yAxisFormatterOptions = {
            decimals: this._config.yAxisColumnFormula.formatPrecision,
            forceSign: this._config.yAxisColumnFormula.formatForceSign ?? false
        };
        this._sizeDimensionFormatter = this._getDimensionFormatter(
            this._config.sizeDimension.field
        );
    }

    private _getDimensionFormatter(field: string): BubbleChartDimensionFormatter {
        const schemeField: FieldInfo = this._schemeLookup()[field];
        let format;

        switch (schemeField.type.toLowerCase()) {
            case "money":
                format = "money";
                break;

            case "float":
                format = "float";
                break;

            default:
                throw Error(`Unsupported field type "${schemeField.type}"`);
        }

        const decimals = schemeField.type === "float" ? schemeField.numericPrecision ?? 0 : 0;

        return {
            format,
            decimals,
            forceSign: false
        };
    }

    private _chartPrepareLevelData(): void {
        const path: Array<number | string> = [];
        for (let i = 0; i < this._levels.length - 1; i++) {
            const filterValue = this._getSingleSelectedOption(this._levels[i], this._filters);
            if (filterValue == null) break;
            path.push(filterValue);
        }

        this._setCurrentPath(path);
        this._copyCurrentPivotLevel();
        this._extractFilteredFields();
    }

    private _setCurrentPath(path: Array<number | string>): void {
        this._chartPath = path;
        this._chartCurrentLevel = path.length;

        const keyFieldName = this._levels[this._chartCurrentLevel];
        this._chartCurrentLevelKeyField = this._schemeLookup()[keyFieldName];
    }

    private _extractFilteredFields(): void {
        const keyField = this._chartCurrentLevelKeyField?.field;
        if (keyField == null) return;
        this._filteredItems = Object.keys(this._filters.filters[keyField]).filter(
            item => item !== "$empty"
        );
    }

    private _getSingleSelectedOption(filterName: string, filters: LgFilterSet<any, any>): any {
        const value = filters.filters[filterName];
        if (value == null || value.$empty) {
            return undefined;
        }

        const keys = _.keys(value);
        if (keys.length === 1) {
            const filterDefinition = filters.getFilterDefinition(filterName);
            // TODO: Some better typing?
            let parseId = (x: any): void => x;
            if (filterDefinition.filterType === "combo2") {
                if ((filterDefinition as IComboFilter2Definition<any>).idType === "number") {
                    parseId = x => parseFloat(x);
                }
            }
            return parseId(keys[0]);
        } else {
            return undefined;
        }
    }

    private _copyCurrentPivotLevel(): void {
        if (this._chartCurrentLevelKeyField == null) throw Error("Field shouldn't be undefined.");

        const data = this._pivot?.filtered;

        if (data == null) {
            this._chartLevelData = [];
            return;
        }

        let level: any[] = data;
        for (let i = 0; i < this._chartPath.length; i++) {
            const keyField = this._levels[i];
            const item = _.find(level, { [keyField]: this._chartPath[i] });
            if (item == null) {
                this._setCurrentPath(_.take(this._chartPath, i));
                break;
            }
            level = item.filteredChildren;
        }

        const sizeKey = this._pageReferences.isAllowed()
            ? this._config.sizeDimension.field + this._config.sizeDimension.reference
            : this._config.sizeDimension.field;

        this._chartLevelData = level.map(item => ({
            xValue: item.formula0,
            yValue: item.formula1,
            size: item[sizeKey],
            groupId: item[this._chartCurrentLevelKeyField!.field]
        }));
    }

    _chartGetGroupName = (datum: LgBubbleChartDatum): string => {
        const code = datum.groupId as string;

        if (this._chartCurrentLevelKeyField?.type == null)
            throw Error("Current level key field shouldn't be undefined.");

        return getItemNameFromField(
            code,
            this._chartCurrentLevelKeyField.type,
            this._definitions,
            "codeAndName"
        );
    };

    _chartOnClick(event: ChartClickEvent<LgBubbleChartDatum>): void {
        if (event.item == null) return;

        const keyField = this._chartCurrentLevelKeyField!.field;

        const filterHandler = this._filters.getRenderer<any, ComboFilterRenderer2<any>>(keyField!);
        filterHandler.toggleItem(event.item.groupId);
        this._filters.triggerOnChanged(keyField!);
    }

    _chartGoUp(): void {
        if (this._chartCurrentLevel <= 0) return;

        this._filters.clear(this._levels[this._chartCurrentLevel - 1]);
    }

    // ----------------------------------------------------------------------------------
    override _export(option: ExportFormat): void {
        switch (option) {
            case ExportFormat.XLSX: {
                this._exportService.export(
                    this._pivot!,
                    this._flexDataClient.scheme,
                    this._levels,
                    this._visibleColumns,
                    this._pageReferences.selectedReferences,
                    this._config.title
                );
                break;
            }
            case ExportFormat.SVG: {
                const svg = cloneElementWithStyles(
                    this.rectEl?.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvg(svg, getExportFilename(this._config.title));
                break;
            }
            case ExportFormat.PNG: {
                const svg = cloneElementWithStyles(
                    this.rectEl?.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvgToPng(
                    svg,
                    2000,
                    getExportFilename(
                        this._config.title ?? this._lgTranslate.translate(".WidgetTitle")
                    )
                );
                break;
            }
        }
    }

    private _referenceTitleTransform(value: string | null | undefined): string {
        if (value == null) return "";

        const selectedReferences = this._isDefaultDataSource()
            ? this._pageReferences.selectedReferences
            : this._references();

        // Matches $REFx occurrences and is case insensitive. For example $REF1, $Ref2, $ref3
        const referenceRegex = /\$REF\d+/gi;
        return value.replace(referenceRegex, refVar => {
            const referenceNumber = parseInt(refVar.match(/\d+/)![0]);
            const reference = selectedReferences.at(referenceNumber - 1);

            if (reference === undefined) return refVar;
            if (reference.name != null) return reference.name;
            if (reference.nameLc != null) return this._lgTranslate.translate(reference.nameLc);
            return "-";
        });
    }
}
