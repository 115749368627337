import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Component, computed, ElementRef, Inject, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import {
    ComboFilterRenderer2,
    IComboFilter2Definition,
    LgFilterSet
} from "@logex/framework/lg-filterset";
import {
    ChartClickEvent,
    IPieChartTooltipContext,
    LgComparePieChartComponent
} from "@logex/framework/lg-charts";
import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";
import { INodeStateStore, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";

import { PieChartWidgetConfigurator } from "./pie-chart-widget-configurator";
import { FlexiblePivotDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-definition-factory";
import {
    ExportFormat,
    FieldInfo,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    PivotTableColumn,
    PivotTableColumnDefault,
    Widget,
    WidgetUsage
} from "../../types";
import {
    PIE_CHART_WIDGET,
    PieChartWidgetConfig,
    PieChartWidgetState
} from "./pie-chart-widget.types";
import { ChartWidgetBaseComponent } from "../base/chart-widget-base/chart-widget-base.component";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry/widget-types-registry.service";
import { FlexibleDatasetDataArgumentsGetter } from "../../services/flexible-dataset/types/types";
import { getColumnFieldName } from "../../utilities/getColumnFieldName";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import {
    cloneElementWithStyles,
    getExportFilename
} from "../../services/widget-export/widget-export-utils";
import { exportSvg, exportSvgToPng } from "../../services/widget-export/widget-export-image";
import { translateNullableName } from "../../utilities";
import { getItemNameFromField } from "../../utilities/getItemNameFromField";

@Component({
    selector: "lgflex-pie-chart-widget",
    templateUrl: "./pie-chart-widget.component.html",
    styleUrls: ["./pie-chart-widget.component.scss"],
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    viewProviders: [...useTranslationNamespace("_Flexible.PieChartWidget")]
})
@Widget({
    id: PIE_CHART_WIDGET,
    nameLc: "_Flexible.PieChartWidget.WidgetTitle",
    usage: WidgetUsage.Page,
    configurator: PieChartWidgetConfigurator,
    configVersion: 1
})
export class PieChartWidgetComponent extends ChartWidgetBaseComponent<PieChartWidgetConfig> {
    constructor(
        promptDialog: LgPromptDialog,
        lgTranslate: LgTranslateService,
        lgConsole: LgConsole,
        flexDataClient: FlexDataClientService,
        filters: LgFilterSet<any, any>,
        pageReferences: PageReferencesService,
        widgetTypes: WidgetTypesRegistry,
        pivotInstanceFactory: LgPivotInstanceFactory,
        flexiblePivotFactory: FlexiblePivotDefinitionFactory,
        @Inject(LG_FLEX_LAYOUT_WIDGET_HOST) widgetHost: IWidgetHost,
        configurator: PieChartWidgetConfigurator,
        exportService: WidgetExportExcelService,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>
    ) {
        super(
            promptDialog,
            lgTranslate,
            lgConsole,
            flexDataClient,
            filters,
            pageReferences,
            widgetTypes,
            pivotInstanceFactory,
            flexiblePivotFactory,
            widgetHost,
            configurator as any,
            exportService
        );
    }

    // ----------------------------------------------------------------------------------
    @ViewChild("rect", { read: ElementRef }) rectEl: ElementRef | undefined;
    @ViewChild("lgComparePieChart") lgComparePieChart!: LgComparePieChartComponent;

    // Chart fields
    private _chartValueFields: string[] = [];
    _chartValueFieldNameLc: string | undefined;
    _chartValueFieldName: string | undefined;

    private _chartPath: Array<number | string> = [];
    _chartCurrentLevel = 0;
    _chartCurrentLevelKeyField: FieldInfo | undefined;
    _chartLevelData: any[] = [];

    private _chartReferenceSlots = new Set<number>();
    private _chartReferenceNames: string[] = [];

    _chartValueFormatter: "money" | "int" | "float" = "float";
    _chartValueDecimals = 0;

    _tooltipType!: IPieChartTooltipContext;
    _chartLabelDisplayMode: DefinitionDisplayMode = "code";

    protected _schemeLookup = computed(() => _.keyBy(this._scheme(), "field"));
    // ----------------------------------------------------------------------------------

    override async setConfig(config: PieChartWidgetConfig): Promise<void> {
        await super.setConfig(config);

        if (!this._isConfigValid) return;
        this._prepareChartFields(config.columns);
        this._chartLabelDisplayMode = config.labelDisplayMode ?? this._chartLabelDisplayMode;
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    override ngOnInit(): void {
        super.ngOnInit();

        if (!this._isConfigValid) return;
        // Update the chart when filter changes
        this._filters.onChanged.pipe(takeUntil(this._destroyed$)).subscribe(filters => {
            if (!_.isEmpty(_.intersection(filters, this._levels))) {
                this._chartPrepareLevelData();
            }
        });

        if (this._pageReferences.isAllowed()) {
            this._prepareReferenceNames();
            this._pageReferences.stateChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
                this._prepareReferenceNames();
            });
        }
    }

    override getState(): PieChartWidgetState | null {
        if (!this._isConfigValid) return null;
        const table = super.getState();
        if (!table) return null;
        return {
            ...table,
            version: 2
        };
    }

    override setState(state: PieChartWidgetState): boolean {
        if (!super.setState(state)) return false;
        this._chartPrepareLevelData();
        return true;
    }

    override getWidgetType(): string {
        return PIE_CHART_WIDGET;
    }

    protected override _buildPivot(data: unknown[], state: INodeStateStore): void {
        super._buildPivot(data, state);

        this._chartPrepareLevelData();
    }

    private _prepareReferenceNames(): void {
        this._chartReferenceNames = [...this._chartReferenceSlots].map(slot => {
            const ref = this._pageReferences.selectedReferences.find(x => x.slotIdx === slot);
            return `${translateNullableName(this._lgTranslate, ref?.name, ref?.nameLc)} (Ref. ${
                slot + 1
            })`;
        });
    }

    // ----------------------------------------------------------------------------------
    // Chart
    _getMin(values: number[]): number {
        return Math.min(...values);
    }

    private _prepareChartFields(columns: PivotTableColumn[]): void {
        const enabledColumns = columns.filter(
            x => x.type === "default" && x.isEnabled !== false
        ) as PivotTableColumnDefault[];

        if (this._pageReferences.isAllowed()) {
            enabledColumns.forEach(column => {
                if (
                    column.referenceIdx == null ||
                    this._pageReferences.selectedReferences[column.referenceIdx] == null
                ) {
                    throw Error("Column is missing valid reference configuration.");
                }
            });
        }

        const schemeFields = new Set(
            enabledColumns.map(
                column => column.type === "default" && this._schemeLookup()[column.field]
            )
        );

        if (schemeFields.size !== 1) {
            throw Error("PieChartWidget can display only one field");
        }

        const schemeField: FieldInfo = schemeFields.values().next().value;

        if (!schemeField.isValueField) {
            throw Error(`Field "${schemeField.field}" is not value field`);
        }

        if (this._pageReferences.isAllowed() && !schemeField.isReferenceBound) {
            throw Error(`Field "${schemeField.field}" is not reference-bound`);
        }

        this._chartReferenceSlots = new Set(
            enabledColumns.map(x => x.referenceIdx).filter((x): x is number => x != null)
        );
        this._chartValueFields = enabledColumns.map(column => getColumnFieldName(column));
        this._chartValueFieldNameLc = schemeField.nameLc ?? "";
        this._chartValueFieldName = schemeField.name ?? "";

        switch (schemeField.type.toLowerCase()) {
            case "money":
                this._chartValueFormatter = "money";
                break;

            case "float":
                this._chartValueFormatter = "float";
                break;

            default:
                throw Error(`Unsupported field type "${schemeField.type}"`);
        }

        this._chartValueDecimals =
            schemeField.type === "float" && schemeField.numericPrecision != null
                ? schemeField.numericPrecision
                : 0;
    }

    private _chartPrepareLevelData(): void {
        const path: Array<number | string> = [];
        for (let i = 0; i < this._levels.length - 1; i++) {
            const filterValue = this._getSingleSelectedOption(this._levels[i], this._filters);
            if (filterValue == null) break;
            path.push(filterValue);
        }

        this._setCurrentPath(path);
        this._copyCurrentPivotLevel();
    }

    private _setCurrentPath(path: Array<number | string>): void {
        this._chartPath = path;
        this._chartCurrentLevel = path.length;

        const keyFieldName = this._levels[this._chartCurrentLevel];
        this._chartCurrentLevelKeyField = this._schemeLookup()[keyFieldName];
    }

    private _getSingleSelectedOption(filterName: string, filters: LgFilterSet<any, any>): any {
        const value = filters.filters[filterName];
        if (value == null || value.$empty) {
            return undefined;
        }

        const keys = _.keys(value);
        if (keys.length === 1) {
            const filterDefinition = filters.getFilterDefinition(filterName);
            let parseId = (x: any): void => x;
            if (filterDefinition.filterType === "combo2") {
                if ((filterDefinition as IComboFilter2Definition<any>).idType === "number") {
                    parseId = x => parseFloat(x);
                }
            }
            return parseId(keys[0]);
        } else {
            return undefined;
        }
    }

    private _copyCurrentPivotLevel(): void {
        const data = this._pivot?.filtered;

        if (data == null) {
            this._chartLevelData = [];
            return;
        }

        let level: any[] = data;
        for (let i = 0; i < this._chartPath.length; i++) {
            const keyField = this._levels[i];
            const item = _.find(level, { [keyField]: this._chartPath[i] });
            if (item === undefined) {
                this._setCurrentPath(_.take(this._chartPath, i));
                break;
            }
            level = item.filteredChildren;
        }

        this._chartLevelData = level
            .filter(item => this._chartValueFields.some(field => item[field] > 0))
            .sort((a, b) => b[this._chartValueFields[0]] - a[this._chartValueFields[0]]);
    }

    _chartGetLabel = (row: Record<string, string | number>): string => {
        if (this._chartCurrentLevelKeyField == null)
            throw Error("Current level key field shouldn't be undefined.");

        const code = row[this._chartCurrentLevelKeyField.field];

        return getItemNameFromField(
            code,
            this._chartCurrentLevelKeyField.type,
            this._definitions,
            this._chartLabelDisplayMode
        );
    };

    _chartGetName = (row: Record<string, string | number>): string => {
        if (this._chartCurrentLevelKeyField == null)
            throw Error("Current level key field shouldn't be undefined.");

        const code = row[this._chartCurrentLevelKeyField.field];

        return getItemNameFromField(
            code,
            this._chartCurrentLevelKeyField.type,
            this._definitions,
            "codeAndName"
        );
    };

    _chartGetGroupNames = (): string[] =>
        this._chartReferenceNames?.length > 0 ? this._chartReferenceNames : [""];

    _chartGetGroupValues = (row: Record<string, number>): number[] =>
        this._chartValueFields.map(key => row[key]);

    _chartGetColumnOpacity = (row: Record<string, string>): number => {
        const keyField = this._chartCurrentLevelKeyField?.field;
        if (keyField == null) throw Error("Field shouldn't be undefined.");

        if (!this._filters.isActive(keyField)) return 1;
        if (row == null) return 0.25;

        const key = row[keyField];
        return this._filters.filters[keyField][key] ? 1 : 0.25;
    };

    _chartOnClick(event: ChartClickEvent<any>): void {
        if (event.item == null) return;

        const keyField = this._chartCurrentLevelKeyField?.field;
        if (keyField == null) throw Error("Field shouldn't be undefined.");

        const filterHandler = this._filters.getRenderer<any, ComboFilterRenderer2<any>>(keyField);
        filterHandler.toggleItem(event.item[keyField]);
        this._filters.triggerOnChanged(keyField);
    }

    _chartGoUp(): void {
        if (this._chartCurrentLevel <= 0) return;

        this._filters.clear(this._levels[this._chartCurrentLevel - 1]);
    }

    // ----------------------------------------------------------------------------------
    override _export(option: ExportFormat): void {
        switch (option) {
            case ExportFormat.XLSX: {
                this._exportService.export(
                    this._pivot!,
                    this._scheme(),
                    this._levels,
                    this._visibleColumns,
                    this._pageReferences.selectedReferences,
                    this._config.title
                );
                break;
            }
            case ExportFormat.SVG: {
                const svg = cloneElementWithStyles(
                    this.rectEl!.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvg(svg, getExportFilename(this._config.title));
                break;
            }
            case ExportFormat.PNG: {
                const svg = cloneElementWithStyles(
                    this.rectEl!.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvgToPng(
                    svg,
                    2000,
                    getExportFilename(
                        this._config.title ?? this._lgTranslate.translate(".WidgetTitle")
                    )
                );
                break;
            }
        }
    }
}
