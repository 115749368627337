import { firstValueFrom } from "rxjs";
import { Injectable } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { UserSettingsService, WellKnownSettingCode } from "@logex/framework/lg-application";
import { loadScript } from "@logex/framework/utilities";

@Injectable()
export class AppLgLocalizationSettings implements LgLocalizationSettings {
    constructor(
        private _lgLoaderService: LgLoaderService,
        private _userSettingsService: UserSettingsService
    ) {
        this.languages = {};

        this.bootstrapDone = new Promise(resolve => {
            this.setReady = resolve;
        });
    }

    // ----------------------------------------------------------------------------------

    availableLanguages: string[];
    fallbackLanguage: string;
    languages: Record<string, any[]>;
    preferredLanguage: string;
    locale: string;
    currency: string;
    bootstrapDone: Promise<void>;

    setReady: () => void;

    // ----------------------------------------------------------------------------------

    setPreferredLanguage(lang: string): void {
        this.preferredLanguage = lang;
    }

    addStrings(lang: string, strings: _.Dictionary<any>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    async switchCurrentLanguage(lang: string): Promise<void> {
        this._lgLoaderService.show();

        await firstValueFrom(
            this._userSettingsService.set({
                key: { storageId: WellKnownSettingCode.uiSettings },
                value: { language: lang }
            })
        );

        window.location.reload();
    }

    loadCrowdinInContext(): void {
        const url = "jipt-scripts.js";
        loadScript(url, true).then(
            () => {
                // crowdin in-context loaded
            },
            () => {
                this.deleteUserPreferedLanguage();
                window.location.reload();
            }
        );
    }

    async deleteUserPreferedLanguage(): Promise<void> {
        await firstValueFrom(
            this._userSettingsService.set({
                key: { storageId: WellKnownSettingCode.uiSettings },
                value: null
            })
        );
    }
}
