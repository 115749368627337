import { computed, inject, Injectable, signal } from "@angular/core";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";
import { ReferenceInfo, ReferenceSlot } from "../../types";
import { FlexibleDataset } from "../../services/flexible-dataset";

@Injectable()
export class PivotTableDataService {
    _layoutDataSource = inject(FlexibleLayoutDataSourcesService);

    private _dataSourceSignal = signal(null);
    private _schemeSignal = signal(null);
    private _referencesSignal = signal<ReferenceInfo[]>([]);
    private _datasetSignal = signal<FlexibleDataset>(null);

    public dataSourceCode = this._dataSourceSignal.asReadonly();
    public scheme = this._schemeSignal.asReadonly();
    public references = this._referencesSignal.asReadonly();
    public dataset = this._datasetSignal.asReadonly();
    public isDefaultDataSource = computed(
        () =>
            this._dataSourceSignal() === null ||
            this._layoutDataSource.defaultLayoutDataSourceCode() === this._dataSourceSignal()
    );

    async getDataSource(dataSourceCode?: string): Promise<any> {
        this._dataSourceSignal.set(dataSourceCode ?? null);
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSource.defaultLayoutDataSourceCode();
        }

        const { scheme, references, dataset } =
            await this._layoutDataSource.getDataSource(dataSourceCode);

        this._schemeSignal.set(scheme);
        this._referencesSignal.set(references);
        this._datasetSignal.set(dataset);

        return { scheme, references };
    }

    public setSelectedReferences(references: ReferenceSlot[], widgetId: string): void {
        this._layoutDataSource.updateDataSourceSelectedReferences(
            widgetId,
            this.dataSourceCode(),
            references
        );
    }
}
