import { inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import {
    IAppConfiguration,
    LG_APP_CONFIGURATION,
    ServerGatewayBase
} from "@logex/framework/lg-application";

import {
    ExportTypeItem,
    GetExportTypeArguments,
    RequestTicketArguments,
    TicketItem
} from "./exports-notifications-gateway.types";

@Injectable()
export class ExportsNotificationsGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfig = inject<IAppConfiguration>(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfig.applicationRoot);
    }

    getExportType(args: GetExportTypeArguments): Observable<ExportTypeItem> {
        return this._get("exports/exportType", { params: args });
    }

    requestTicket(args: RequestTicketArguments): Observable<TicketItem> {
        return this._post<TicketItem>("exports/ticket", { body: args });
    }
}
