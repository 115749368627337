import { Injectable } from "@angular/core";
import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";

@Injectable()
export class QueuePivot extends PivotProviderBase {
    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "exportId",
            store: "children",
            levelId: "row1",
            defaultOrderBy: "-createdAt",

            calculateOnce: {
                steps: [
                    "isExpandable = SOME( element.exportId != null )",
                    "$expanded = IDENTITY( isExpandable ) AT PARENT"
                ],
                thisLevelOnly: true
            }
        };
    }
}
