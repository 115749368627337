import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    inject,
    Inject,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal
} from "@angular/core";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { mixins } from "@logex/mixin-flavors";
import { LgPromptDialog, LgFormatTypePipe, LgMarkSymbolsPipe } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";
import { HandleErrorsMixin, NgOnDestroyMixin } from "@logex/mixins";
import {
    IColumnExportDefinition,
    LgExcelFactoryService,
    LogexXlsxApi
} from "@logex/framework/lg-exports";

import {
    ExportFormat,
    FieldInfo,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    MAX_FILENAME_LENGTH,
    MAX_SHEET_NAME_LENGTH,
    ReferenceSlot,
    Widget,
    WidgetComponent,
    WidgetUsage
} from "../../types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import {
    FlexibleDataset,
    FlexibleDatasetDataArgumentsGetter
} from "../../services/flexible-dataset";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import {
    getAllFilters,
    getColumnFieldName,
    parseCalculate,
    PrimitiveArray,
    translateNullableName
} from "../../utilities";
import {
    CROSSTAB_WIDGET,
    CrosstabWidgetConfig,
    CrosstabWidgetState
} from "./crosstab-widget.types";
import { CrosstabWidgetConfigurator } from "./crosstab-widget-configurator";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import { getExportFilename } from "../../services/widget-export/widget-export-utils";
import { getItemNameFromField } from "../../utilities/getItemNameFromField";
import { CHART_WIDGET } from "../chart";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";
import { omitBy } from "lodash-es";

export interface CrosstabWidgetComponent extends HandleErrorsMixin, NgOnDestroyMixin {}

const ROW_HEIGHT = 28;
const CELL_PADDING = 24;

@Component({
    selector: "lgflex-crosstab-widget",
    templateUrl: "./crosstab-widget.component.html",
    styleUrls: ["./crosstab-widget.component.scss"],
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [...useTranslationNamespace("_Flexible.CrosstabWidget")]
})
@Widget({
    id: CROSSTAB_WIDGET,
    nameLc: "_Flexible.CrosstabWidget.WidgetTitle",
    usage: WidgetUsage.Page,
    configurator: CrosstabWidgetConfigurator,
    configVersion: 1
})
@mixins(HandleErrorsMixin, NgOnDestroyMixin)
export class CrosstabWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
    private readonly _layoutDataSource = inject(FlexibleLayoutDataSourcesService);
    constructor(
        private _changeDetector: ChangeDetectorRef,
        public _promptDialog: LgPromptDialog,
        public _lgTranslate: LgTranslateService,
        protected _lgConsole: LgConsole,
        public _flexDataClient: FlexDataClientService,
        public _filters: LgFilterSet<any, any>,
        public _pageReferences: PageReferencesService,
        public _widgetTypes: WidgetTypesRegistry,
        private _formatter: LgFormatTypePipe,
        private _lgMarkSymbols: LgMarkSymbolsPipe,
        public _exportService: WidgetExportExcelService,
        @Inject(LG_FLEX_LAYOUT_WIDGET_HOST) protected _widgetHost: IWidgetHost,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>,
        configurator: CrosstabWidgetConfigurator,
        lgXlsxService: LgExcelFactoryService
    ) {
        this._initMixins();

        this._configurator = configurator;
        this._lgXlsx = lgXlsxService.create();
        this._lgXlsx.setDefaultStyle(this._lgXlsx.styles.logex);

        this._isLoading$ = of(true);
        this._isConfiguring$ = new BehaviorSubject<boolean>(true);
    }

    // ----------------------------------------------------------------------------------

    @ViewChild("crosstabHeader") crosstabHeader: ElementRef | undefined;
    @ViewChild("crosstabSidebar") crosstabSidebar: ElementRef | undefined;
    @ViewChild("scroller") scroller: CdkVirtualScrollViewport | undefined;

    // Config
    _config!: CrosstabWidgetConfig;
    private _isActivated = false;
    _isValid = false;
    private _reconfigureSubject = new Subject<void>();

    private _id: string | undefined;
    protected _lgXlsx: LogexXlsxApi;

    private _horizontalField: Readonly<FieldInfo> | undefined;
    private _verticalField: Readonly<FieldInfo> | undefined;
    private _valueField: Readonly<FieldInfo> | undefined;

    _colNames: any[] = [];
    _rowNames: any[] = [];
    _data: any[][] | null = null;

    _isAutoConverted = false;
    _isDataComplete = true;
    private _dataBuildCount = 0;

    _isLoading$: Observable<boolean>;
    _isConfiguring$: Subject<boolean>;
    _isCalculating$: Observable<boolean> | undefined;
    _calculationProgress$: Observable<number | undefined> | undefined;
    private _dataSubscription: Subscription | null = null;

    _mouseOverColIdx: number | null = null;
    _mouseOverRowIdx: number | null = null;

    _valueFormatter: ((val: string | number | null | undefined) => string | number) | undefined;
    _sidebarWidth: number | undefined;
    _cellWidth: number | undefined;

    protected _configurator: CrosstabWidgetConfigurator;
    private _pendingState: CrosstabWidgetState | null = null;
    private _dataSource = signal(null);
    private _scheme = signal(null);
    private _references = signal(null);
    private _dataset: WritableSignal<FlexibleDataset> = signal(null);
    private _isDefaultDataSource = computed(
        () =>
            this._dataSource() === null ||
            this._layoutDataSource.defaultLayoutDataSourceCode() === this._dataSource()
    );

    protected _exportOptions = [ExportFormat.XLSX];

    // ----------------------------------------------------------------------------------
    setConfig(config: CrosstabWidgetConfig): void {
        this._config = _.clone(config);

        // Reactivate
        if (this._isActivated) {
            this._reconfigure();
        }
    }

    setContext(context: object): void {
        // Empty
    }

    setId(id: string): void {
        this._id = id;
    }

    markAsAutoConverted(): void {
        this._isAutoConverted = true;
    }

    ngOnInit(): void {
        this._reconfigure();

        this._isActivated = true;
    }

    onVisibilityChange(isVisible: boolean): void {
        if (!isVisible && this._dataSubscription != null) {
            this._dataSubscription.unsubscribe();
            this._dataSubscription = null;
        } else if (isVisible) {
            this._resubscribeToData();
        }
    }

    getState(): CrosstabWidgetState | null {
        if (
            !this._isValid ||
            !this._colNames?.length ||
            !this._rowNames?.length ||
            this.crosstabHeader == null ||
            this.crosstabSidebar == null ||
            this._cellWidth == null ||
            this._data == null
        )
            return null;
        const xIndex = Math.min(
            Math.floor(
                this.crosstabHeader.nativeElement.scrollLeft / (this._cellWidth + CELL_PADDING)
            ),
            this._colNames.length - 1
        );
        const yIndex = Math.min(
            Math.floor(this.crosstabSidebar.nativeElement.scrollTop / ROW_HEIGHT),
            this._data.length - 1
        );
        return {
            version: 1,
            xPos: this._colNames[xIndex],
            yPos: this._rowNames[yIndex]
        };
    }

    setState(state: CrosstabWidgetState): boolean {
        if (state.version !== 1) return false; // handle if necessary
        this._pendingState = state;
        if (this._isActivated && this._colNames?.length) {
            this._setStateDo();
        }
        return true;
    }

    getWidgetType(): string {
        return CROSSTAB_WIDGET;
    }

    private _setStateDo(): void {
        let x = 0;
        let y = 0;
        const xIndex = this._colNames.indexOf(this._pendingState!.xPos);
        if (xIndex !== -1) x = xIndex * (this._cellWidth! + CELL_PADDING);
        const yIndex = this._rowNames.indexOf(this._pendingState!.yPos);
        if (yIndex !== -1) y = yIndex * ROW_HEIGHT;
        if (x || y) {
            if (this.scroller != null) {
                this.scroller.scrollTo({ left: x, top: y, behavior: "auto" });
            } else {
                requestAnimationFrame(() =>
                    this.scroller?.scrollTo({ left: x, top: y, behavior: "auto" })
                );
            }
        }
        this._pendingState = null;
    }

    protected async _reconfigure(): Promise<void> {
        this._reconfigureSubject.next();
        this._isConfiguring$.next(true);

        // Validate fields
        this._isValid = this._configurator.validate(this._config);

        if (this._isValid) {
            await this._updateDataSource(this._config.dataSource);

            this._setSelectedReferences(this._config.selectedReferences, this._id);

            // Find field definitions
            this._horizontalField = this._findFieldInfo(this._config.horizontalDimension);
            this._verticalField = this._findFieldInfo(this._config.verticalDimension);
            this._valueField = this._findFieldInfo(this._config.value);

            this._sidebarWidth = this._config.sidebarWidth;
            this._cellWidth = this._config.cellWidth;

            // Initialize the value formatter
            switch (this._valueField!.type) {
                case "float":
                    this._valueFormatter = value =>
                        this._formatter.transform(
                            value,
                            "float",
                            this._valueField!.numericPrecision ?? undefined
                        );
                    break;
                case "money":
                    this._valueFormatter = value =>
                        this._lgMarkSymbols.transform(
                            this._formatter.transform(
                                value,
                                "money",
                                this._valueField!.numericPrecision ?? undefined
                            )
                        );
                    break;
                default:
                    this._valueFormatter = value => value ?? 0;
                    break;
            }

            // Configure data loading
            this._resubscribeToData();
        }

        this._isConfiguring$.next(false);
    }

    private _resubscribeToData() {
        if (!this._isValid) return;

        const fields = this._getRequiredFields();
        const args = this._getFlexDatasetArguments();
        this._dataSubscription = this._dataset()
            .dataAsObservable(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)))
            .subscribe({
                next: data => {
                    this._buildCrosstab(data.data);
                    this._isDataComplete = data.isComplete;
                    if (this._pendingState) this._setStateDo();
                    this._changeDetector.markForCheck();
                },
                error: err => {
                    this._onServerFailure(err);
                    this._isDataComplete = true;
                    this._changeDetector.markForCheck();
                }
            });

        this._isLoading$ = this._dataset()
            .isLoading$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));

        this._isCalculating$ = this._dataset()
            .isCalculating$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));

        this._calculationProgress$ = this._dataset()
            .calculationProgress$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));
    }

    protected _getRequiredFields(): string[] {
        let valueFields = [this._valueField!.field];
        if (this._valueField!.calculate != null) {
            const parsed = parseCalculate(this._valueField!.calculate);
            if (parsed.type === "functionCall") {
                valueFields = parsed.params;
            }
        }

        return [this._horizontalField!.field, this._verticalField!.field, ...valueFields];
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _findFieldInfo(field: string) {
        const fieldInfo = this._scheme().find(x => x.field === field);

        if (fieldInfo === undefined) {
            this._lgConsole.error(`Field ${field} is not found in the fields schema`);
        }

        return fieldInfo;
    }

    private _getFieldName(field: string): string {
        return getColumnFieldName({
            type: "default",
            field,
            referenceIdx: this._config.referenceIdx
        });
    }

    protected _getFlexDatasetArguments(): FlexibleDatasetDataArgumentsGetter | null {
        const originalArgs = this._flexDataClient.dataset.dataArguments;
        if (originalArgs.references) {
            originalArgs.references = () =>
                !this._isDefaultDataSource()
                    ? this._getSelectedReferencesCodes()
                    : this._pageReferences.selected;
        }

        if (originalArgs.filters) {
            originalArgs.filters = () => {
                const nonEmptyFilters = omitBy(
                    getAllFilters(this._filters),
                    x => x == null
                ) as Record<string, PrimitiveArray>;
                return this._dataset().getValidFilters(nonEmptyFilters);
            };
        }

        return originalArgs;
    }

    // TODO: Add better typing than any
    protected _buildCrosstab(data: any[]): void {
        this._data = null;

        if (
            this._horizontalField == null ||
            this._verticalField == null ||
            this._valueField == null
        )
            throw Error("Fields shouldn't be undefined.");

        const horizontalFieldName = this._horizontalField.field;
        if (isDefinitionTypeField(this._horizontalField)) {
            // Normal dimension
            const horizontalDefinition = this._horizontalField.type;
            this._colNames = _.sortBy(_.uniq(_.map(data, x => x[horizontalFieldName])), x =>
                this._definitions.getOrderBy(horizontalDefinition, x)
            );
        } else {
            // This is definitions-less dimension
            this._colNames = _.sortBy(_.uniq(_.map(data, x => x[horizontalFieldName])));
        }
        const horizontalPositions = _.reduce(
            this._colNames,
            (a, x, i) => {
                a[x] = i;
                return a;
            },
            {} as Dictionary<number>
        );

        const verticalFieldName = this._verticalField.field;
        if (isDefinitionTypeField(this._verticalField)) {
            // Normal dimension
            const verticalDefinition = this._verticalField.type;
            this._rowNames = _.sortBy(_.uniq(_.map(data, x => x[verticalFieldName])), x =>
                this._definitions.getOrderBy(verticalDefinition, x)
            );
        } else {
            // This is definitions-less dimension
            this._rowNames = _.sortBy(_.uniq(_.map(data, x => x[verticalFieldName])));
        }
        const verticalPositions = _.reduce(
            this._rowNames,
            (a, x, i) => {
                a[x] = i;
                return a;
            },
            {} as Dictionary<number>
        );

        const matrix = _.times(this._rowNames.length, () =>
            _.times(this._colNames.length, () => 0)
        );

        for (const x of data) {
            let value;

            if (this._valueField.calculate !== null) {
                const parsed = parseCalculate(this._valueField.calculate);
                if (parsed.type === "functionCall" && parsed.func === "div") {
                    const valueField1Name = this._getFieldName(parsed.params[0]);
                    const valueField2Name = this._getFieldName(parsed.params[1]);
                    value = x[valueField1Name] / x[valueField2Name];
                }
            } else {
                const valueFieldName = this._getFieldName(this._valueField.field);
                value = x[valueFieldName];
            }

            const vpos = verticalPositions[x[verticalFieldName]];
            const hpos = horizontalPositions[x[horizontalFieldName]];
            matrix[vpos][hpos] = value;
        }

        this._data = matrix;

        if (++this._dataBuildCount === 1) {
            this._widgetHost.notifyWidgetReady(this._id!);
        }
    }

    onScroll(e: Event): void {
        const target = e.target as HTMLDivElement;
        this.crosstabHeader!.nativeElement.scrollLeft = target.scrollLeft;
        this.crosstabSidebar!.nativeElement.scrollTop = target.scrollTop;
    }

    onMouseOver(e: MouseEvent): void {
        const target = document.elementFromPoint(e.x, e.y);
        const xValRaw =
            target?.getAttribute("highlight-x") ??
            target?.parentElement?.getAttribute("highlight-x");
        const yValRaw =
            target?.getAttribute("highlight-y") ??
            target?.parentElement?.getAttribute("highlight-y");
        const xVal = xValRaw != null ? Number(xValRaw) : null;
        const yVal = yValRaw != null ? Number(yValRaw) : null;
        this._mouseOverColIdx = xVal;
        this._mouseOverRowIdx = yVal;
    }

    fmtCol(colIdx: number, displayMode?: DefinitionDisplayMode): string {
        const code = this._colNames[colIdx];

        return isDefinitionTypeField(this._horizontalField!)
            ? this._definitions.getDisplayName(this._horizontalField!.type, code, displayMode)
            : code;
    }

    fmtRowName(rowIdx: number): string {
        const code = this._rowNames[rowIdx];

        return getItemNameFromField(code, this._verticalField!.type, this._definitions);
    }

    _export(): void {
        this._lgXlsx.saveArray(
            {
                filename: getExportFilename(this._config.title || "").slice(0, MAX_FILENAME_LENGTH),
                sheetName: this._config.title?.slice(0, MAX_SHEET_NAME_LENGTH),
                bigHeader: this._config.title,
                itemName: "cell"
            },
            this._getColumnDefinitions(),
            this._filters.getExportDefinition(),
            this._scheme(),
            this._getExportData(this._data || [])
        );
    }

    _getExportData(data: any[][]): any[][] {
        const rows: any[][] = [];
        for (let i = 0; i < this._rowNames.length; i++) {
            rows.push([this.fmtRowName(i), ...data[i]]);
        }
        return rows;
    }

    _getColumnDefinitions(): IColumnExportDefinition[] {
        const definitions: IColumnExportDefinition[] = [];
        definitions.push({
            // name column
            name: `${translateNullableName(
                this._lgTranslate,
                this._verticalField?.name,
                this._verticalField?.nameLc
            )} \\ ${translateNullableName(
                this._lgTranslate,
                this._horizontalField?.name,
                this._horizontalField?.nameLc
            )}`,
            width: 50,
            contentFn: locals => locals.cell[0],
            hAlign: "left"
        });
        const format: string = this._exportService.getExcelFormat(
            this._findFieldInfo(this._config.value)!
        );
        for (let i = 0; i < this._colNames.length; i++) {
            definitions.push({
                name: this.fmtCol(i, "code") ?? "-",
                contentFn: locals => locals.cell[i + 1] ?? 0,
                width: 50,
                format,
                hAlign: "right",
                separator: i === 0
            });
        }
        return definitions;
    }

    async showConfigurationUi(): Promise<void> {
        const config = await this._configurator.show(this._config);

        this._isConfiguring$.next(true);
        this._changeDetector.markForCheck();

        const configVersion = this._widgetTypes.getWidgetConfigVersion(this);
        await this._widgetHost.updateWidgetConfiguration(
            this._id!,
            this.getWidgetType(),
            configVersion,
            config
        );

        this.setConfig(config);
    }

    private async _updateDataSource(dataSourceCode?: string): Promise<any> {
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSource.defaultLayoutDataSourceCode();
        }

        this._dataSource.set(dataSourceCode);
        const { scheme, references, dataset } =
            await this._layoutDataSource.getDataSource(dataSourceCode);

        this._scheme.set(scheme);
        this._references.set(references);
        this._dataset.set(dataset);

        return { scheme, references };
    }

    private _setSelectedReferences(references: ReferenceSlot[], widgetId: string): void {
        this._layoutDataSource.updateDataSourceSelectedReferences(
            widgetId,
            this._dataSource(),
            references
        );
    }

    private _getSelectedReferencesCodes(): string[] {
        return this._config.selectedReferences
            ? this._config.selectedReferences.map(reference => reference.referenceCode)
            : this._pageReferences.selected;
    }
}
