export type ExportStatusInfo = {
    exportId: string;
    status: ExportStatus;
    error: string;
    createdAt: Date;
    startedAt: Date;
    dateTime: Date;
};

export enum ExportStatus {
    Queued = 1,
    InProgress = 2,
    Success = 3,
    Cancelled = 4,
    Error = 5
}
