import { inject, Injectable } from "@angular/core";
import {
    ExportItem,
    GetExportsArguments,
    GetExportTypesArguments,
    RequestExportsArguments,
    RequestTicketArguments,
    TicketItem
} from "./export-gateway.types";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    LG_APP_CONFIGURATION,
    MaybeStaleData,
    ServerGatewayBase
} from "@logex/framework/lg-application";
import { ExportTypeItem } from "../../../gateways/exports-notifications-gateway.types";

@Injectable()
export class ExportGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    getExportTypes(args: GetExportTypesArguments): Observable<ExportTypeItem[]> {
        return this._get("exports/exportTypes", { params: args });
    }

    getExports(args: GetExportsArguments): Observable<ExportItem[]> {
        return this._get<ExportItem[]>("exports/exports", { params: args }).pipe(
            map(items => items.map(x => this._transformExportItem(x)))
        );
    }

    requestExport(url: string, args: RequestExportsArguments): Observable<ExportItem> {
        return this._post<ExportItem>(url, { body: args }).pipe(
            map(item => this._transformExportItem(item))
        );
    }

    requestTicket(args: RequestTicketArguments): Observable<TicketItem> {
        return this._post<TicketItem>("exports/ticket", { body: args });
    }

    private _transformExportItem(x: ExportItem): ExportItem {
        return {
            ...x,
            createdAt: x.createdAt != null ? new Date(x.createdAt) : null,
            finishedAt: x.finishedAt != null ? new Date(x.finishedAt) : null,
            startedAt: x.startedAt != null ? new Date(x.startedAt) : null
        };
    }

    selectFilter(filterUid: string, args: any): Observable<MaybeStaleData<any[]>> {
        throw Error("Not implemented");
    }
}
