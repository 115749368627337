import { inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";
import { HandleErrorsMixin } from "@logex/mixins";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IScenario, LG_APP_CONFIGURATION, LG_APP_SESSION } from "@logex/framework/lg-application";
import { firstValueFrom } from "rxjs";
import { urlConcat } from "@logex/framework/utilities";
import { ExportsNotificationsGateway } from "../gateways/exports-notifications-gateway.service";
import { AppSession } from "../../../common";

export interface DownloadExportService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class DownloadExportService {
    _promptDialog = inject(LgPromptDialog);
    _lgTranslate = inject(LgTranslateService);
    private _session = inject<AppSession<IScenario>>(LG_APP_SESSION);
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _gateway = inject(ExportsNotificationsGateway);

    async do(exportId: string): Promise<void> {
        const req = await firstValueFrom(
            this._gateway.requestTicket({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                exportId
            })
        );

        const form = document.createElement("form");
        form.method = "POST";
        form.action = urlConcat(this._appConfiguration.applicationRoot, "exports/download");
        const el = document.createElement("input");
        el.type = "hidden";
        el.name = "ticketId";
        el.value = req.ticketId;
        form.appendChild(el);
        const a = document.body.appendChild(form);
        form.submit();
        document.body.removeChild(a);
    }
}
