import { Component, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";

import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import {
    ChartConfigurationDialogBase,
    ChartWidgetConfigurationDialogArgumentsBase,
    ChartWidgetConfigurationDialogResponseBase
} from "../../../../components/base/chart-configuration-dialog-base";
import { IconChartWidgetIcons } from "../../types/icons.enum";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry/widget-types-registry.service";
import { validateIconChartConfiguration } from "./icon-chart-configuration-validation";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";

export interface IconChartWidgetConfigurationDialogArguments
    extends ChartWidgetConfigurationDialogArgumentsBase {
    icon?: IconChartWidgetIcons;
}

export interface IconChartWidgetConfigurationDialogResponse
    extends ChartWidgetConfigurationDialogResponseBase {
    icon: IconChartWidgetIcons;
}

@Component({
    selector: "lgflex-icon-chart-configuration-dialog",
    templateUrl: "./icon-chart-configuration-dialog.component.html",
    styleUrls: [
        "../../../../components/base/chart-configuration-dialog-base/chart-configuration-dialog-base.component.scss"
    ],
    providers: [...useTranslationNamespace("_Flexible.IconChartWidgetDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class IconChartConfigurationDialogComponent extends ChartConfigurationDialogBase<
    IconChartWidgetConfigurationDialogArguments,
    IconChartWidgetConfigurationDialogResponse
> {
    constructor(
        @Inject(LG_APP_DEFINITIONS) definitions: IDefinitions<any>,
        lgTranslate: LgTranslateService,
        dialogRef: LgDialogRef<IconChartConfigurationDialogComponent>,
        promptDialog: LgPromptDialog,
        _widgetTypes: WidgetTypesRegistry
    ) {
        super(definitions, lgTranslate, dialogRef, promptDialog, _widgetTypes);
    }

    _icon: IconChartWidgetIcons = IconChartWidgetIcons.Patient;
    _iconDropdown: IDropdownDefinition<IconChartWidgetIcons> | undefined;

    // ----------------------------------------------------------------------------------
    //
    override async _activate(): Promise<void> {
        this._initializeIcon();
        await super._activate();
    }

    private _initializeIcon(): void {
        this._icon = this._args.icon ?? IconChartWidgetIcons.Patient;

        this._iconDropdown = dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: Object.keys(IconChartWidgetIcons).map(key => ({
                code: IconChartWidgetIcons[key as keyof typeof IconChartWidgetIcons],
                name: this._lgTranslate.translate(`_Flexible.IconChartWidget.Icons.${key}`)
            }))
        });
    }

    override async _save(): Promise<boolean> {
        this._resolve({
            widgetName: this._widgetName,
            widgetDescription: this._widgetDescription,
            widgetTooltip: this._widgetTooltip,
            widgetTooltipLink: this._widgetTooltipLink,
            dataSource: this._dataSourceCode(),
            selectedReferences: this._selectedReferences(),
            levels: this._selectedLevelsIds(),
            columns: this._getResponseColumns()!,
            icon: this._icon,
            ignoreOwnFilters: this._ignoreOwnFilters
        });

        this._close();

        return true;
    }

    override _isValid(): boolean {
        const columns = this._getResponseColumns();
        if (columns == null) return false;

        return validateIconChartConfiguration(
            {
                title: this._widgetName,
                description: this._widgetDescription,
                levels: this._selectedLevelsIds(),
                columns,
                icon: this._icon
            },
            this._isDefaultDataSource()
                ? this._args.pageReferences
                : ({
                      isAllowed: () => true,
                      slots: this._selectedReferences()
                  } as unknown as PageReferencesService),
            this._scheme()
        );
    }
}

@Injectable()
export class IconChartConfigurationDialog extends getDialogFactoryBase(
    IconChartConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
