import { cloneDeep, first, includes, merge } from "lodash";
import { firstValueFrom } from "rxjs";
import { inject, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LgConsole } from "@logex/framework/core";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";
import {
    KeyValuePair,
    LG_APP_CONFIGURATION,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LG_USER_INFO,
    OidcAuthService,
    UiSettings,
    UserSettingsService,
    WellKnownSettingCode
} from "@logex/framework/lg-application";
import { loadJsonFile, urlConcat } from "@logex/framework/utilities";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { AppLgLocalizationSettings } from "../app-lg-localization-settings";
import { AppConfiguration, IAppSettings } from "./types";

const IN_CONTEXT_LANGUAGE = "zu-ZA";

export abstract class AppStartupServiceBase<TAppSettings extends IAppSettings> {
    protected _http = inject(HttpClient);
    protected _lgConsole = inject(LgConsole);
    protected _localizationSettings = inject<AppLgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    protected _userSettingsService = inject(UserSettingsService);
    protected _appConfiguration = inject<AppConfiguration<TAppSettings>>(LG_APP_CONFIGURATION);
    protected _promptDialog = inject(LgPromptDialog);
    protected _userInfo = inject(LG_USER_INFO);
    protected _oidcAuthService = inject<OidcAuthService>(LG_AUTHENTICATION_SERVICE);
    protected _injector = inject(Injector);

    getInitializer(): () => Promise<void> {
        return async () => {
            try {
                await this._initialize();
            } catch (e: any) {
                this._lgConsole.error("Error initializing application", e);

                await this._promptDialog.alert("Error initializing application", e.message, {
                    allowClose: false
                });

                throw e;
            }
        };
    }

    protected async _initialize(): Promise<boolean> {
        this._lgConsole.debug("Application initialization");

        const basePath = this._getBasePath();

        this._addBaseHref(basePath);

        let config = await this._loadStaticConfiguration();

        if (!(await this._authenticate(config, basePath))) {
            // Authentication is not finished yet - redirect required
            return false;
        }

        // Extend configuration with dynamically loaded configuration
        config = await this._extendConfiguration(config);

        this._fillAppConfiguration(config);

        await this._initUserSettingsService(config);
        await this._configureLocalization(config);

        await this._fetchUserInfo();

        return true;
    }

    protected async _extendConfiguration(config: TAppSettings): Promise<TAppSettings> {
        const dynamicConfig = await this._loadConfiguration(config);
        merge(config, dynamicConfig);
        this._lgConsole.debug("Full configuration", config);
        return config;
    }

    private async _loadConfiguration(config: TAppSettings): Promise<Partial<TAppSettings>> {
        if (config.services.configuration != null) {
            const data = await firstValueFrom(
                this._http.get<TAppSettings>(
                    urlConcat(config.services.configuration, "/configuration"),
                    {
                        params: {
                            instance: config.instance,
                            environment: config.environment
                        },
                        headers: { "api-version": "1.0" }
                    }
                )
            );

            this._lgConsole.debug("Dynamic configuration received", data);

            return data;
        } else {
            this._lgConsole.debug("No dynamic configuration endpoint specified. Skipping.");
            return {};
        }
    }

    protected async _initUserSettingsService(config: TAppSettings): Promise<void> {
        this._userSettingsService.initialize({
            instance: config.instance,
            environment: config.environment,
            url: config.services.userSettings
        });
    }

    protected abstract _getBasePath(): string;

    protected _addBaseHref(basePath: string): void {
        let baseEl: HTMLBaseElement = first(document.head.getElementsByTagName("base"));

        if (baseEl == null) {
            baseEl = document.createElement("base");
            baseEl.setAttribute("href", basePath);
            document.head.appendChild(baseEl);
        }
    }

    protected async _loadStaticConfiguration(): Promise<TAppSettings> {
        const [config1, config2] = await Promise.all([
            loadJsonFile<TAppSettings>("appsettings.json", false),
            loadJsonFile<Partial<TAppSettings>>("appsettings.override.json", true)
        ]);

        const config = merge(config1, config2);

        // TODO: validate config

        // eslint-disable-next-line
        delete config["$schema"];
        this._lgConsole.debug("Static configuration received", cloneDeep(config));
        return config;
    }

    protected async _authenticate(config: IAppSettings, basePath: string): Promise<boolean> {
        this._oidcAuthService.configure({
            authority: config.services.authentication.authority,
            type: config.services.authentication.type ?? "generic",
            clientId: config.services.authentication.clientId,
            audience: config.services.authentication.audience,
            basePath,
            redirectUrl: window.location.href
        });
        const authenticated = await this._oidcAuthService.login();

        if (!authenticated) {
            return false;
        }

        this._userInfo.userid = this._oidcAuthService.user.login;

        return true;
    }

    protected async _configureLocalization(config: TAppSettings): Promise<void> {
        this._localizationSettings.availableLanguages = config.availableLanguages.split(",");
        this._localizationSettings.fallbackLanguage = config.fallbackLanguage;
        this._localizationSettings.preferredLanguage = config.defaultLanguage;
        this._localizationSettings.locale = config.defaultLanguage;
        this._localizationSettings.currency = config.currency;

        if (config.enableLocalizationEditor) {
            this._localizationSettings.availableLanguages.push(IN_CONTEXT_LANGUAGE);
        }
        let uiSettings;
        const crowdinInContextEscape = localStorage.getItem("crowdinInContextEscape");
        if (crowdinInContextEscape != null) {
            await this._localizationSettings.deleteUserPreferedLanguage();
            localStorage.removeItem("crowdinInContextEscape");
        } else {
            // Get user's language
            uiSettings = first(
                await firstValueFrom(
                    this._userSettingsService.get({ storageId: WellKnownSettingCode.uiSettings })
                )
            ) as KeyValuePair<any, UiSettings>;
        }
        if (uiSettings != null && uiSettings.value != null) {
            const lang = uiSettings.value.language;
            if (includes(this._localizationSettings.availableLanguages, lang)) {
                this._localizationSettings.preferredLanguage = lang;
                this._localizationSettings.locale = lang;
            }
        }

        if (
            config.enableLocalizationEditor &&
            this._localizationSettings.preferredLanguage === IN_CONTEXT_LANGUAGE
        ) {
            this._localizationSettings.loadCrowdinInContext();
        }

        this._localizationSettings.setReady();
    }

    protected _fillAppConfiguration(config: TAppSettings): void {
        this._appConfiguration.appSettings = config;

        this._appConfiguration.instance = config.instance;
        this._appConfiguration.environment = config.environment;

        this._appConfiguration.testMachine = "";
        const environment = config.environment?.toLowerCase();
        if (environment !== "production") {
            if (environment === "localhost") {
                this._appConfiguration.testMachine = "dev";
            } else {
                this._appConfiguration.testMachine = `${config.instance} - ${environment}`;
            }
        }

        this._appConfiguration.applicationInsightsInstrumentationKey =
            config.applicationInsights?.instrumentationKey;
        this._appConfiguration.applicationInsightsExcludeDomains =
            config.applicationInsights?.excludeDomains;

        this._appConfiguration.applicationRoot = config.services.application;

        this._appConfiguration.userflowEnvironmentKey = config.userFlow?.environmentKey;
        this._appConfiguration.userflowContentId = config.userFlow?.contentId;

        this._appConfiguration.markReady();
    }

    protected async _fetchUserInfo(): Promise<void> {
        const authorizationService = this._injector.get(LG_AUTHORIZATION_SERVICE);
        const userProfile = await firstValueFrom(authorizationService.getUserProfile());

        this._lgConsole.debug("User profile", userProfile);
        this._userInfo.name = userProfile.name;
        this._userInfo.impersonator = userProfile.impersonator;
        this._userInfo.login = userProfile.login;
    }
}
