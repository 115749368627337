import {
    AppUser,
    IAppInfo,
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    IScenario,
    LgMatTrackingService
} from "@logex/framework/lg-application";
import { AppSession } from "../common";

// setup of custom dimensions is same for all environments (at least for now)
const ORGANIZATION_CODE_DIMENSION = 1;
const ORGANIZATION_NAME_DIMENSION = 2;

export function configureMatomoBasicDimensions<AppScenario extends IScenario>(
    service: LgMatTrackingService,
    values: {
        session: AppSession<AppScenario>;
        userInfo: AppUser;
        appInfo: IAppInfo;
    }
): void {
    service.deleteCustomDimension(ORGANIZATION_CODE_DIMENSION);
    service.setCustomDimension(ORGANIZATION_CODE_DIMENSION, values.session.client.id);

    service.deleteCustomDimension(ORGANIZATION_NAME_DIMENSION);
    service.setCustomDimension(ORGANIZATION_NAME_DIMENSION, values.session.client.name);
}

export class MatomoConfiguration implements IMatomoConfiguration {
    constructor(cfg: {
        statUrl: string;
        siteId: number;
        useUserEmail?: boolean;
        cookieDomain?: string;
        siteDomains?: string[];
        enableCrossDomainLinking?: boolean;
    }) {
        this.statUrl = cfg.statUrl;
        this.siteId = cfg.siteId;
        this.useUserEmail = cfg.useUserEmail;
        this.commonDimensionIds = {};
        this.customDimensions = [];
        this.cookieDomain = cfg.cookieDomain;
        this.siteDomains = cfg.siteDomains;
        this.enableCrossDomainLinking = cfg.enableCrossDomainLinking;
    }

    statUrl: string;
    siteId: number;
    useUserEmail: boolean;
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions: IMatomoDimension[];
    autoInit = false;
    autoTrack = true;
    cookieDomain?: string;
    siteDomains?: string[];
    enableCrossDomainLinking?: boolean;

    doNotDoMatTracking(): boolean {
        return false;
    }
}
