<div class="flexible-layout-editor__body--holder">
    <div
        [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
        class="flexible-layout-editor--widgets-holder"
    >
        <div class="form-group">
            <div class="form-row form-row--input" *ngIf="_dataSources !== undefined">
                <div class="form-row__label">{{ ".DataSources" | lgTranslate }}:</div>
                <div class="form-row__controls--2col">
                    <lg-dropdown
                        [definition]="_dataSourcesDropdown"
                        [current]="_currentDataSourceCode!"
                        (currentChange)="onDataSourceChange($event)"
                        [emptyAcceptable]="false"
                        className="lg-dropdown"
                    ></lg-dropdown>
                </div>
            </div>
            <div
                class="form-row"
                *ngIf="_arePageReferencesAllowed && _referenceSlots !== undefined"
            >
                <div class="form-row__label">{{ ".ReferenceSlots" | lgTranslate }}:</div>
                <div
                    class="form-row__controls flexible-layout-editor__references"
                    [class.form-row__controls--warning]="_numReferences === 0"
                >
                    {{ ".ReferenceSlotsCount" | lgTranslate: { count: _numReferences } }} &nbsp;

                    <lg-button
                        buttonClass="button button--condensed"
                        (click)="_editReferences()"
                        textLc=".ReferenceSlotsEdit"
                    ></lg-button>
                    @if (!_isReferenceSlotsValid()) {
                        <div class="flexible-layout-editor__invalid-message">
                            {{ ".InvalidReferenceSlots" | lgTranslate }}
                        </div>
                    }
                </div>
            </div>
            <div class="form-row--input form-row">
                <div class="form-row__label">{{ ".Arrangement" | lgTranslate }}:</div>
                <div class="form-row__controls">
                    <lgflex-icon-selector
                        [options]="_layoutOptions!"
                        [current]="_currentLayout?.code"
                        (currentChange)="_onLayoutChange($event)"
                    ></lgflex-icon-selector>
                </div>
            </div>
        </div>
        <div class="form-group" cdkDropListGroup>
            <div class="form-row">
                <div class="form-row__controls">
                    <div class="lgg lgg--horizontal">
                        <div class="lgg__col lgg__col--4">
                            <div class="lgg__cell">
                                <h3 class="with-spacing">{{ ".Widgets" | lgTranslate }}</h3>

                                <div
                                    class="flexible-layout-editor-dialog__widgets flexible-layout-editor-dialog__widget-configs"
                                    cdkDropList
                                    cdkDropListSortingDisabled
                                    [cdkDropListData]="_widgetTypeList"
                                    (cdkDropListDropped)="_onDropBack($event)"
                                    id="flexible-layout-editor-dialog__widget-configurations"
                                >
                                    <div
                                        class="flexible-layout-editor-dialog__widget flexible-layout-editor-dialog__widget--choice flexible-layout-editor-dialog__widget--draggable"
                                        *ngFor="let item of _widgetTypeList; let i = index"
                                        cdkDrag
                                        (cdkDragStarted)="_widgetDragStart(i)"
                                        (cdkDragDropped)="_widgetDrop(i)"
                                    >
                                        <lg-icon
                                            class="flexible-layout-editor-dialog__widget__icon"
                                            [icon]="_getWidgetIcon(item.id)"
                                        />
                                        <lg-icon
                                            icon="icon-draggable"
                                            class="flexible-layout-editor-dialog__widget__drag-handle"
                                        />
                                        <div
                                            class="flexible-layout-editor-dialog__widget__icons"
                                            *ngIf="item.deprecatedMessage"
                                        >
                                            <lg-icon
                                                icon="icon-warning"
                                                type="warning"
                                                [lgSimpleTooltip]="'=' + item.deprecatedMessage"
                                            ></lg-icon>
                                        </div>
                                        <div class="flexible-layout-editor-dialog__widget__title">
                                            {{ item.nameLc | lgTranslate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lgg__col lgg__col--8">
                            <div class="lgg__cell">
                                <h3 class="with-spacing">{{ ".SelectedWidgets" | lgTranslate }}</h3>

                                <div
                                    class="flexible-layout-editor-dialog__layout-config lgg lgg--vertical"
                                >
                                    <div
                                        class="lgg__row lgg__row--{{ _currentLayoutRowsFactor }}"
                                        *ngFor="let row of _currentLayoutRows"
                                    >
                                        <div
                                            class="lgg__col lgg__col--{{
                                                _currentLayoutColsFactor(row)
                                            }}"
                                            *ngFor="let col of _currentLayoutCols(row)"
                                        >
                                            <div
                                                class="lgg__row lgg__row--{{
                                                    _currentLayoutColRowsFactor(col)
                                                }}"
                                                *ngFor="let colRow of _currentColRows(col)"
                                            >
                                                <div class="lgg__cell">
                                                    <div class="lg-panel__header"></div>

                                                    <div class="lg-block--table">
                                                        <div class="lg-panel__body">
                                                            <div
                                                                class="flexible-layout-editor-dialog__widgets_target_wrapper"
                                                            >
                                                                <div
                                                                    class="flexible-layout-editor-dialog__widgets_target"
                                                                >
                                                                    <div
                                                                        class="flexible-layout-editor-dialog__widgets_target_inner"
                                                                        cdkDropList
                                                                        cdkDropListOrientation="horizontal"
                                                                        [cdkDropListData]="
                                                                            _widgetConfigs[
                                                                                colRow.id!
                                                                            ]
                                                                        "
                                                                        (cdkDropListDropped)="
                                                                            _onDrop(
                                                                                colRow.id!,
                                                                                $event
                                                                            )
                                                                        "
                                                                        (cdkDropListEntered)="
                                                                            _onEnter(
                                                                                colRow.id!,
                                                                                $event
                                                                            )
                                                                        "
                                                                        (cdkDropListExited)="
                                                                            _onLeave()
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="flexible-layout-editor-dialog__widget flexible-layout-editor-dialog__widget--draggable"
                                                                            *ngFor="
                                                                                let widget of _widgetConfigs[
                                                                                    colRow.id!
                                                                                ];
                                                                                let i = index
                                                                            "
                                                                            cdkDrag
                                                                            [class.flexible-layout-editor-dialog__widget--invalid]="
                                                                                !widget.isValid
                                                                            "
                                                                        >
                                                                            <lg-icon
                                                                                class="flexible-layout-editor-dialog__widget__icon"
                                                                                [icon]="
                                                                                    _getWidgetIcon(
                                                                                        widget.type
                                                                                    )
                                                                                "
                                                                            />
                                                                            <lg-icon
                                                                                icon="icon-draggable"
                                                                                class="flexible-layout-editor-dialog__widget__drag-handle"
                                                                            ></lg-icon>
                                                                            <div
                                                                                class="flexible-layout-editor-dialog__widget__icons"
                                                                                [class.flexible-layout-editor-dialog__widget__icons--active]="
                                                                                    widget.replacementDropdownActive
                                                                                "
                                                                            >
                                                                                <lg-icon-menu
                                                                                    *ngIf="
                                                                                        widget.replacementDropdown
                                                                                    "
                                                                                    [definition]="
                                                                                        widget.replacementDropdown
                                                                                    "
                                                                                    [title]="
                                                                                        '.Click_to_convert'
                                                                                            | lgTranslate
                                                                                    "
                                                                                    icon="icon-doorbelasting-from"
                                                                                    [condensed]="
                                                                                        true
                                                                                    "
                                                                                    [current]="
                                                                                        widget.type
                                                                                    "
                                                                                    (currentChange)="
                                                                                        _confirmAndConvertWidget(
                                                                                            widget,
                                                                                            $event
                                                                                        )
                                                                                    "
                                                                                    (activeChange)="
                                                                                        widget.replacementDropdownActive =
                                                                                            $event
                                                                                    "
                                                                                ></lg-icon-menu>
                                                                                @if (
                                                                                    widget.convertedToMessage
                                                                                ) {
                                                                                    <lg-icon
                                                                                        icon="icon-info"
                                                                                        [lgSimpleTooltip]="
                                                                                            widget.convertedToMessage
                                                                                        "
                                                                                    ></lg-icon>
                                                                                }

                                                                                <lg-icon
                                                                                    icon="icon-edit"
                                                                                    [clickable]="
                                                                                        true
                                                                                    "
                                                                                    *ngIf="
                                                                                        !widget.isUnknown &&
                                                                                        widget.isConfigurable
                                                                                    "
                                                                                    (click)="
                                                                                        _editWidget(
                                                                                            colRow.id!,
                                                                                            i
                                                                                        )
                                                                                    "
                                                                                ></lg-icon>
                                                                                <lg-icon
                                                                                    icon="icon-delete"
                                                                                    [clickable]="
                                                                                        true
                                                                                    "
                                                                                    (click)="
                                                                                        _removeWidget(
                                                                                            colRow.id!,
                                                                                            i
                                                                                        )
                                                                                    "
                                                                                ></lg-icon>
                                                                                <lg-icon
                                                                                    *ngIf="
                                                                                        widget.deprecatedMessage
                                                                                    "
                                                                                    icon="icon-warning"
                                                                                    type="warning"
                                                                                    [lgSimpleTooltip]="
                                                                                        '=' +
                                                                                        widget.deprecatedMessage
                                                                                    "
                                                                                ></lg-icon>
                                                                                <lg-icon
                                                                                    icon="icon-error-x"
                                                                                    *ngIf="
                                                                                        widget.isUnknown
                                                                                    "
                                                                                    type="error"
                                                                                    lgSimpleTooltip=".UnknownWidget"
                                                                                ></lg-icon>
                                                                            </div>
                                                                            <div
                                                                                class="flexible-layout-editor-dialog__widget__body"
                                                                            >
                                                                                <div
                                                                                    [lgLongText]="
                                                                                        widget
                                                                                            .config
                                                                                            .title ??
                                                                                            ''
                                                                                            | modReferenceVariable
                                                                                    "
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="flexible-layout-editor-dialog__widget-text"
                                                                            *ngIf="
                                                                                !_isDraggingOverPane(
                                                                                    colRow.id!
                                                                                ) &&
                                                                                !_isPaneHasWidgets(
                                                                                    colRow.id!
                                                                                )
                                                                            "
                                                                        >
                                                                            {{
                                                                                ".NoWidgetsLine1"
                                                                                    | lgTranslate
                                                                            }}<br />
                                                                            {{
                                                                                ".NoWidgetsLine2"
                                                                                    | lgTranslate
                                                                            }}<br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group form-group--buttons">
            <div class="form-row">
                <span class="form-row--button-left">
                    <ng-container *ngIf="_showNormalLayoutButtons()">
                        <lg-button textLc=".Rename" (click)="_renameLayout()"></lg-button>

                        <lg-button
                            buttonClass="button--error"
                            textLc="FW.Delete"
                            (click)="_deleteLayout()"
                        ></lg-button>
                    </ng-container>

                    <lg-button
                        *ngIf="_showTemporaryLayoutButtons()"
                        buttonClass="button--error"
                        textLc=".Revert"
                        (click)="_revertTemporaryLayout()"
                    ></lg-button>
                </span>

                <lg-button
                    buttonClass="button--warning"
                    textLc=".Apply"
                    (click)="_applyLayout()"
                    [isDisabled]="!_isFormValid()"
                ></lg-button>

                <lg-button
                    *ngIf="_showNormalLayoutButtons()"
                    buttonClass="button--primary"
                    textLc="FW.SAVE"
                    (click)="_saveLayout()"
                    [isDisabled]="!_isFormValid()"
                ></lg-button>

                <lg-button
                    *ngIf="_showTemporaryLayoutSaveButton()"
                    buttonClass="button--primary"
                    textLc="FW.SAVE"
                    (click)="_overrideByTemporaryLayout()"
                    [isDisabled]="!_isFormValid()"
                ></lg-button>

                <lg-button
                    buttonClass="button--primary"
                    textLc=".SaveAsNew"
                    (click)="_saveAsNewLayout()"
                    [isDisabled]="!_isFormValid()"
                ></lg-button>

                <!--suppress TypeScriptUnresolvedFunction -->
                <lg-button textLc="FW.CANCEL" (click)="_close()"></lg-button>
            </div>
        </div>
    </div>
    <div class="flexible-layout-editor--filters-holder">
        <lg-filters-panel
            style="width: 320px"
            [showPanelWithoutSlideout]="true"
            [searchFilterIds]="true"
            [hideDefaultFooter]="true"
            [expandGroupsByDefault]="true"
        ></lg-filters-panel>
    </div>
</div>
