import * as _ from "lodash-es";
import { computed, inject, Inject, OnDestroy, OnInit, signal } from "@angular/core";
import { asapScheduler, filter, merge, Observable, of, Subject, Subscription } from "rxjs";
import { observeOn, take, takeUntil } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import {
    INodeStateStore,
    LgPivotInstance,
    LgPivotInstanceFactory
} from "@logex/framework/lg-pivot";
import { LgFilterSet } from "@logex/framework/lg-filterset";

import { HandleErrorsMixin, NgOnDestroyMixin } from "@logex/mixins";

import {
    ExportFormat,
    FdpDifferenceColumnChangeEvent,
    FieldInfo,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    PivotTableColumn,
    ReferenceInfo,
    WidgetComponent,
    WidgetConfigurator
} from "../../../types";
import { FlexDataClientService } from "../../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../../services/page-references/page-references.service";
import {
    FlexibleDataset,
    FlexibleDatasetDataArgumentsGetter
} from "../../../services/flexible-dataset";
import { FlexiblePivotDefinitionFactory } from "../../../services/flexible-pivot/flexible-pivot-definition-factory";
import { WidgetTypesRegistry } from "../../../services/widget-types-registry";
import { PivotRow, ChartWidgetStateBase, ChartWidgetConfigBase } from "./chart-widget-base.types";
import { ChartWidgetConfiguratorBase } from "./chart-widget-base-configurator";
import { WidgetExportExcelService } from "../../../services/widget-export/widget-export-excel.service";
import { applyPivotExpanded, extractPivotExpanded } from "../../../utilities";
import { SelectDataResponse } from "../../../services/flexible-dataset/types/types";
import { FlexibleLayoutDataSourcesService } from "../../../services/flexible-layout-data-sources";

export interface ChartWidgetBaseComponent<TConfig> extends HandleErrorsMixin, NgOnDestroyMixin {}

// ChartWidgetBase
@mixins(HandleErrorsMixin, NgOnDestroyMixin)
export abstract class ChartWidgetBaseComponent<
        TConfig extends ChartWidgetConfigBase = ChartWidgetConfigBase
    >
    implements OnInit, OnDestroy, WidgetComponent
{
    protected _layoutDataSourceService = inject(FlexibleLayoutDataSourcesService);

    constructor(
        public _promptDialog: LgPromptDialog,
        public _lgTranslate: LgTranslateService,
        protected _lgConsole: LgConsole,
        public _flexDataClient: FlexDataClientService,
        public _filters: LgFilterSet<any, any>,
        public _pageReferences: PageReferencesService,
        public _widgetTypes: WidgetTypesRegistry,
        private _pivotInstanceFactory: LgPivotInstanceFactory,
        private _flexiblePivotFactory: FlexiblePivotDefinitionFactory,
        @Inject(LG_FLEX_LAYOUT_WIDGET_HOST) protected _widgetHost: IWidgetHost,
        configurator: ChartWidgetConfiguratorBase,
        public _exportService: WidgetExportExcelService
    ) {
        this._initMixins();

        this._configurator = configurator;

        this._pivot = null;
        this._isLoading$ = of(true);
    }

    // ----------------------------------------------------------------------------------

    // Config
    _config!: TConfig;
    _isConfigValid = false;
    private _isActivated = false;
    private _reconfigureSubject = new Subject<void>();

    private _id: string | undefined;
    private _dataSubscription: Subscription | null = null;

    _levels: string[] = [];
    _columns: PivotTableColumn[] = [];
    _visibleColumns: PivotTableColumn[] = [];

    _pivot: LgPivotInstance<PivotRow, unknown> | null;
    _isDataComplete = true;
    _isAutoConverted = false;
    private _pivotBuildCount = 0;

    _isLoading$: Observable<boolean>;
    _isCalculating$: Observable<boolean> | undefined;
    _calculationProgress$: Observable<number | undefined> | undefined;

    private _pendingState: ChartWidgetStateBase | null = null;
    protected _configurator: WidgetConfigurator<ChartWidgetConfigBase>;

    protected _exportOptions = [ExportFormat.XLSX];

    protected _scheme = signal<FieldInfo[]>([]);
    protected _dataset = signal<FlexibleDataset>(null);
    protected _references = signal<ReferenceInfo[]>([]);
    protected _dataSourceCode = signal<string | null>(null);

    protected _isDefaultDataSource = computed(
        () =>
            this._dataSourceCode() === null ||
            this._dataSourceCode() === this._layoutDataSourceService.defaultLayoutDataSourceCode()
    );

    // ----------------------------------------------------------------------------------
    async setConfig(config: TConfig): Promise<void> {
        this._config = config;
        this._isConfigValid = this._configurator.validate(config);
        // this._scheme = this._flexDataClient.scheme;
        this._levels = config.levels;
        this.setColumns(config.columns);

        // Reactivate
        if (this._isActivated) {
            await this._reconfigurePivot();
        } else {
            await this.setDataSource(this._config.dataSource);
        }
    }

    onVisibilityChange(isVisible: boolean): void {
        if (!isVisible && this._dataSubscription != null) {
            this._dataSubscription.unsubscribe();
            this._dataSubscription = null;
        } else if (isVisible) {
            this._resubscribeToData();
        }
    }

    setContext(context: object): void {
        // Empty
    }

    setId(id: string): void {
        this._id = id;
    }

    markAsAutoConverted(): void {
        this._isAutoConverted = true;
    }

    ngOnInit(): void {
        this._reconfigurePivot();
        this._isActivated = true;
    }

    getState(): ChartWidgetStateBase | null {
        if (!this._isConfigValid || !this._levels.length || !this._pivot) return null;

        return {
            version: 2,
            levels: _.cloneDeep(this._levels),
            orderBy: _.cloneDeep(this._pivot.orderBy),
            expanded: extractPivotExpanded(this._pivot)
        };
    }

    setState(state: ChartWidgetStateBase): boolean {
        if (state.version !== 2) return false; // handle if ended
        return this.doSetBaseState(state);
    }

    abstract getWidgetType(): string;

    protected async setDataSource(dataSourceCode?: string): Promise<void> {
        this._dataSourceCode.set(dataSourceCode ?? null);
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSourceService.defaultLayoutDataSourceCode();
        }

        const { scheme, references, dataset } =
            await this._layoutDataSourceService.getDataSource(dataSourceCode);

        this._scheme.set(scheme);
        this._references.set(references);
        this._dataset.set(dataset);
    }

    protected doSetBaseState(state: ChartWidgetStateBase) {
        if (!_.isEqual(this._levels, state.levels)) return false;
        if (!this._pivot) {
            this._pendingState = state;
        } else {
            this._pendingState = null;
            this._pivot.orderBy = _.cloneDeep(state.orderBy);
            if (state.expanded && _.isEqual(this._levels, state.levels)) {
                const when = this._pivot.all
                    ? // eslint-disable-next-line no-void
                      of(void 0)
                    : this._pivot.onBuild$.pipe(
                          observeOn(asapScheduler),
                          take(1),
                          takeUntil(this._destroyed$)
                      );
                when.subscribe(() => {
                    applyPivotExpanded(this._pivot, state.expanded);
                    this._pivot!.refilter();
                });
            } else {
                this._pivot.refilter();
            }
        }

        return true;
    }

    protected setColumns(columns: PivotTableColumn[]): void {
        this._columns = columns;
        this._visibleColumns = _.filter(columns, x => x.isEnabled !== false);
    }

    protected async _reconfigurePivot(): Promise<void> {
        if (!this._isConfigValid) return;

        await this.setDataSource(this._config.dataSource);

        this._reconfigureSubject.next();

        this._pivot = this._createPivot(
            this._scheme(),
            this._pageReferences.slots.length,
            this._levels,
            this._visibleColumns
        );

        this._resubscribeToData();
    }

    protected _resubscribeToData(): void {
        if (!this._isConfigValid) return;

        const fields = this._getRequiredField();
        const args = this._getFlexDatasetArguments();
        const nodeState = this._extractPivotState();

        this._dataSubscription = this._dataset()
            .dataAsObservable(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)))
            .subscribe({
                next: data => {
                    this._onDataLoaded(data, nodeState);
                },
                error: err => {
                    this._onServerFailure(err);
                    this._isDataComplete = true;
                }
            });

        this._isLoading$ = this._dataset()
            .isLoading$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));

        this._isCalculating$ = this._dataset()
            .isCalculating$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));

        this._calculationProgress$ = this._dataset()
            .calculationProgress$(fields, args ?? undefined)
            .pipe(takeUntil(merge(this._destroyed$, this._reconfigureSubject)));
    }

    protected _getRequiredField(): string[] {
        return _.uniq(
            [
                ...this._levels,
                ..._.map(this._visibleColumns, x => {
                    switch (x.type) {
                        case "default":
                        case "difference":
                            return x.field;

                        case "widget":
                            return null;

                        case "formula":
                            return Object.keys(x.variables).map(y => {
                                const formulaVar = x.variables[y];
                                return formulaVar.type === "constant" ? null : formulaVar.field;
                            });

                        default:
                            throw Error(`Unsupported column type "${(x as any).type}"`);
                    }
                })
            ]
                .flat()
                .filter((x): x is string => x != null)
        );
    }

    protected _getSelectedReferencesCodes(): string[] {
        return this._isDefaultDataSource()
            ? this._pageReferences.selected
            : this._config.selectedReferences.map(reference => reference.referenceCode);
    }

    protected _getFlexDatasetArguments(): FlexibleDatasetDataArgumentsGetter | null {
        const originalArgs = this._flexDataClient.dataset.dataArguments;
        const filterArgs = originalArgs.filters;

        if (originalArgs.references) {
            originalArgs.references = () =>
                !this._isDefaultDataSource()
                    ? this._getSelectedReferencesCodes()
                    : this._pageReferences.selected;
        }

        return {
            ...originalArgs,
            filters: () => {
                let filters = originalArgs.filters() || {};
                if (this._config.ignoreOwnFilters) {
                    filters = _.omit(filterArgs(), ...this._levels);
                }

                return this._dataset().getValidFilters(filters);
            }
        };
    }

    protected _createPivot(
        scheme: FieldInfo[],
        numReferences: number,
        levels: string[],
        columns: PivotTableColumn[]
    ): LgPivotInstance {
        const pivotDef = this._flexiblePivotFactory.getPivotDefinition({
            scheme,
            numReferences,
            levels,
            columns
        });
        return this._pivotInstanceFactory.create(pivotDef, this);
    }

    protected _extractPivotState(): INodeStateStore | null {
        if (this._pivot?.all == null) return null;
        return this._pivot.extractNodeState("$expanded");
    }

    protected _onDataLoaded(data: SelectDataResponse, nodeState: INodeStateStore | null): void {
        if (this._pendingState && this._pivot != null) {
            this._pivot.orderBy = _.cloneDeep(this._pendingState.orderBy);
        }
        this._buildPivot(data.data, nodeState);
        this._isDataComplete = data.isComplete;
        this._pendingState = null;
    }

    protected _buildPivot(data: unknown[], state: INodeStateStore | null): void {
        if (this._pivot === null) throw Error("Pivot shouldn't be undefined.");

        this._pivot.build(data, false, true);

        if (this._pendingState) {
            applyPivotExpanded(this._pivot, this._pendingState.expanded);
        } else if (state !== null) {
            this._pivot.applyNodesState("$expanded", state);
        }

        if (++this._pivotBuildCount === 1) {
            this._widgetHost.notifyWidgetReady(this._id!);
        }
    }

    async showConfigurationUi(): Promise<void> {
        const config = await this._configurator.show(this._config);

        const configVersion = this._widgetTypes.getWidgetConfigVersion(this);
        await this._widgetHost.updateWidgetConfiguration(
            this._id!,
            this.getWidgetType(),
            configVersion,
            config
        );

        this.setConfig(config as TConfig);
    }

    _onDifferenceColumnChange(event: FdpDifferenceColumnChangeEvent): void {
        this.setColumns(
            _.map(this._columns, x => {
                if (x !== event.column) return x;
                return { ...x, ...event.changes };
            })
        );

        // Reactivate
        this._reconfigurePivot();
    }

    _export(option: ExportFormat): void {
        if (this._pivot == null) throw Error("Pivot shouldn't be undefined.");

        switch (option) {
            case ExportFormat.XLSX: {
                this._exportService.export(
                    this._pivot,
                    this._scheme(),
                    this._levels,
                    this._visibleColumns,
                    this._pageReferences.selectedReferences,
                    this._config.title
                );
                break;
            }
        }
    }
}
